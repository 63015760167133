import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { AuthSetState, AuthState } from "./types";

export const initialAuthState: AuthState = {
  hydrated: false,
  token: "",
  phoneNumber: "",
  userId: null,
};

export const useAuthStore = create<AuthState & AuthSetState>()(
  persist(
    immer((set) => ({
      ...initialAuthState,
      setState: (newState: Partial<AuthState>) =>
        set((state: AuthState) => ({ ...state, ...newState })),
      logout: () => set(() => ({ ...initialAuthState, hydrated: true })),
    })),
    {
      name: "auth",
      onRehydrateStorage: () => (state) => {
        return state!.setState({ hydrated: true });
      },
    }
  )
);

export default useAuthStore;
