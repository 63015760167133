import { usePathname } from "next/navigation";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import useAuthStore from "@/store/auth";
import { isProd } from "@/utils";

const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-3DZDJ9CHST";

export const useGoogleAnalyticsBoot = () => {
  const userId = useAuthStore((state) => state.userId);
  const pathname = usePathname();

  useEffect(() => {
    if (isProd()) {
      ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }
  }, []);

  useEffect(() => {
    if (isProd() && !!userId) {
      ReactGA.set({ userId });
    }
  }, [userId]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);
};
