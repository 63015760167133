import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { PrimeReactProvider } from "primereact/api";
import { type ReactNode, useMemo } from "react";
import { ToastContainer } from "react-toastify";

import { BrandingContextProvider } from "@/contexts/branding";
import { FeatureFlagProvider } from "@/contexts/featureFlag";
import { LocalesProvider } from "@/contexts/localesProvider";
import { isServer } from "@/utils";
import { logError } from "@/utils/sentry";

interface ClientProviderProps {
  children: ReactNode;
}

export const ClientProvider = ({ children }: ClientProviderProps) => {
  // use useMemo to avoid creating a new instance of QueryClient on every render
  // in case there is an state added to this provider later.

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            retryOnMount: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            if (!isServer()) {
              logError(error, {
                file: window.location.pathname,
              });
            }
          },
        }),
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider>
        <LocalesProvider>
          <BrandingContextProvider>
            <PrimeReactProvider>
              {children}
              <ToastContainer />
            </PrimeReactProvider>
          </BrandingContextProvider>
        </LocalesProvider>
      </FeatureFlagProvider>
    </QueryClientProvider>
  );
};
