import {
  LDProvider,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";

import { User } from "@/api/BackendAPI";
import { FEATURE_FLAGS, LD_CLIENT_ID } from "@/contexts/featureFlag/constants";
import { useUserInfoQuery } from "@/hooks/queries/useAuthQueries";

const anonymousContext = {
  kind: "user",
  anonymous: true,
  id: "anonymous",
  type: "anonymous",
  email: "anonymous",
  first_name: "anonymous",
  last_name: "anonymous",
  is_ops: false,
  teams: [],
  organizations: [],
};

const createUserContext = (user: User) => {
  return {
    kind: "user",
    key: user.id,
    id: user.id,
    type: user.user_type,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    is_ops: false,
    organizations: user.contractor_orgs.map((org) => org.name),
  };
};

type FeatureFlagsProps = {
  enabled: boolean;
};

export const useFeatureFlagContext = (
  ...flags: FEATURE_FLAGS[]
): FeatureFlagsProps => {
  const ldFlags = useFlags();
  const enabled = flags.every((requiredFlag) => {
    return ldFlags[requiredFlag];
  });

  return {
    enabled,
  };
};

/**
 * Ensures child elements aren't rendered until feature flags have loaded.
 */
const FeatureFlagLoadingWrapper = ({ children }: { children: ReactNode }) => {
  const ldClient = useLDClient();
  const featureFlagsLoaded = !!ldClient;
  if (!featureFlagsLoaded) {
    return null;
  }
  return children;
};

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  // Don't render child components until user info query completes.
  // This ensures we don't re-initialize from anonymous to user context.
  const { data: userInfo, error, isPending } = useUserInfoQuery();
  if (isPending) {
    return null;
  }

  const isAnonymous = error || !userInfo;
  const context = isAnonymous ? anonymousContext : createUserContext(userInfo);

  return (
    <LDProvider
      clientSideID={LD_CLIENT_ID}
      reactOptions={{
        // Needed so we can declare feature flags with dash-case
        useCamelCaseFlagKeys: false,
      }}
      options={{ streaming: false }}
      context={context}>
      <FeatureFlagLoadingWrapper>{children}</FeatureFlagLoadingWrapper>
    </LDProvider>
  );
};
