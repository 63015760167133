import { ReactNode, useEffect, useState } from "react";

import { Spinner } from "@/components/common/spinner";
import { useSupportedLocalesQuery } from "@/hooks/queries/useUtilQueries";
import { configureI18n } from "@/utils/i18n";

interface LocalesProviderProps {
  children: ReactNode;
}

export const LocalesProvider = ({ children }: LocalesProviderProps) => {
  const { data: supportedLocales } = useSupportedLocalesQuery();

  const [translationsLoaded, setTranslationsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (supportedLocales) {
      configureI18n(supportedLocales.user_locale, () =>
        setTranslationsLoaded(true)
      );
    } else {
      setTranslationsLoaded(false);
    }
  }, [setTranslationsLoaded, supportedLocales]);

  if (!supportedLocales || !translationsLoaded) {
    return (
      <main>
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      </main>
    );
  }

  return children;
};
