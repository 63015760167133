import { usePathname } from "next/navigation";

import { AppEnum } from "@/constants";
import { useUserInfoQuery } from "@/hooks/queries/useAuthQueries";

import {
  isAOSmithDefault,
  isAOSmithLowes,
  isAOSmithStateWaterHeaters,
  isSVCE,
} from "./utils";

export const useDetermineApp = () => {
  const { data: user } = useUserInfoQuery();
  const pathname = usePathname();

  const branding = (() => {
    if (isAOSmithDefault({ pathname })) {
      return AppEnum.AOSmithDefault;
    }
    if (isAOSmithStateWaterHeaters({ pathname })) {
      return AppEnum.AOSmithStateWaterHeaters;
    }
    if (isAOSmithLowes({ pathname })) {
      return AppEnum.AOSmithLowes;
    }
    if (isSVCE({ hostname: location.hostname, user })) {
      return AppEnum.SVCE;
    }
    return AppEnum.RockRabbit;
  })();

  return {
    branding,
  };
};
