/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AggregationResponse[ProjectStatsDataPoint] */
export interface AggregationResponseProjectStatsDataPoint {
  /** Data */
  data: ProjectStatsDataPoint[];
  /**
   * End
   * @format date-time
   */
  end: string;
  /**
   * Start
   * @format date-time
   */
  start: string;
}

/** AmountTypeEnum */
export enum AmountTypeEnum {
  Usd = "usd",
  UsdPerUnit = "usd_per_unit",
  UsdPerCondenser = "usd_per_condenser",
  UsdPerKwh = "usd_per_kwh",
  UsdPerTon = "usd_per_ton",
  UsdPerHeatingTon5F = "usd_per_heating_ton_5f",
  UsdPerCoolingTon95F = "usd_per_cooling_ton_95f",
  Percentage = "percentage",
  PercentagePerYear = "percentage_per_year",
  UsdPerYear = "usd_per_year",
}

/** ListProjectsResult */
export interface AppApiApiV1SchemasOpsListProjectsResult {
  /** Projects */
  projects: AppApiApiV1SchemasOpsProjectMinimal[];
  /** Total */
  total: number;
}

/**
 * ProjectMinimal
 * A view of the project used in Ops tooling
 */
export interface AppApiApiV1SchemasOpsProjectMinimal {
  /** Address */
  address: string;
  /** Affiliated Users */
  affiliated_users: TeamUser[];
  /** Applications */
  applications: ApplicationMinimal[];
  /**
   * Created
   * @format date-time
   */
  created: string;
  /** Exception Application Phases */
  exception_application_phases: string[];
  /** Id */
  id: string;
  /** Name */
  name: string;
  /** Submitted Application Phases */
  submitted_application_phases: string[];
  /** Submitting Application Phases */
  submitting_application_phases: string[];
  /** Total Cost */
  total_cost: number | null;
  /**
   * Updated
   * @format date-time
   */
  updated: string;
}

/** QuotableJob */
export interface AppApiApiV1SchemasOpsQuotableJob {
  /** Address */
  address: string;
  /** Aosmith Level */
  aosmith_level: string | null;
  /** Brand */
  brand: string | null;
  /** Contact Email */
  contact_email: string | null;
  /** Contact Name */
  contact_name: string | null;
  /** Contact Phone */
  contact_phone: string | null;
  /** Is Svce Verified */
  is_svce_verified: boolean;
  /** Represents the timeline of a potential job. */
  planned_timeline: JobTimelineEnum;
  /** Represents the preferred contact method of a user. */
  preferred_contact_method: ContactMethodEnum;
  property_type: PropertyTypeEnum;
  /** Quote Requests */
  quote_requests: QuoteRequest[];
  service_type: ServiceTypeEnum;
  /** User Id */
  user_id: string | null;
}

/**
 * QuotableJob
 * A quotable job
 */
export interface AppApiApiV1SchemasQuotesQuotableJob {
  /**
   * Aosmith Level
   * The initial qualification level for AOSmith, if any
   */
  aosmith_level: string | null;
  /**
   * Brand
   * The brand associated with the job
   */
  brand: string | null;
  /**
   * Created At
   * When the job was created
   */
  created_at: string;
  /**
   * Id
   * ID of the quotable job
   */
  id: string;
  /**
   * Is Svce Verified
   * Whether the job is SVCE verified
   */
  is_svce_verified: boolean;
  /**
   * Updated At
   * When the job was last updated
   */
  updated_at: string;
}

/**
 * ProjectMinimal
 * Project view for those without access to the project
 */
export interface AppApiApiV1SchemasTeamsProjectMinimal {
  /**
   * Id
   * The project's ID
   */
  id: string;
  /**
   * Name
   * The project's name
   */
  name: string;
}

/** UserStatus */
export enum AppApiApiV1SchemasUserUserStatus {
  PendingSignup = "pending_signup",
  PendingUserTypeSelection = "pending_user_type_selection",
  PendingTermsAcceptance = "pending_terms_acceptance",
  PendingContractorOrgSelection = "pending_contractor_org_selection",
  PendingContractorOrgApproval = "pending_contractor_org_approval",
  Active = "active",
  Suspended = "suspended",
}

/** UserStatus */
export enum AppEnumsEnumsUserStatus {
  PendingSignup = "pending_signup",
  Active = "active",
  PendingUserTypeSelection = "pending_user_type_selection",
  PendingContractorOrgSelection = "pending_contractor_org_selection",
  PendingContractorOrgApproval = "pending_contractor_org_approval",
  Suspended = "suspended",
}

/** Organization */
export interface AppSchemasIncentiveOrganization {
  /** Abbreviation */
  abbreviation?: string | null;
  /** Contractor Network */
  contractor_network?: string | null;
  /** Covered Geographies */
  covered_geographies?: string[] | null;
  /** Id */
  id: string;
  /** Is Partner */
  is_partner: boolean;
  /** Logourl */
  logoUrl?: string | null;
  /** Name */
  name: string;
  org_type?: OrganizationTypeEnum | null;
  /** Websiteurl */
  websiteUrl?: string | null;
}

/** Organization */
export interface AppSchemasOrganizationOrganization {
  /** Abbreviation */
  abbreviation?: string | null;
  /** Covered Geographies */
  covered_geographies: string[];
  /** Id */
  id: string;
  /** Is Partner */
  is_partner: boolean;
  /** Logourl */
  logoUrl: string;
  /** Name */
  name: string;
  org_type: OrganizationTypeEnum;
  /** Websiteurl */
  websiteUrl?: string | null;
}

/**
 * ListProjectsResult
 * Schema for a list of projects.
 */
export interface AppSchemasProjectListProjectsResult {
  /** Projects */
  projects: ProjectCard[];
  /** Total */
  total: number;
}

/**
 * ApplicationMinimal
 * A view of the application used in Ops tooling
 */
export interface ApplicationMinimal {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /** Id */
  id: string;
  /** Name */
  name: string;
  /** Portal Name */
  portal_name: string | null;
  /**
   * Updated
   * @format date-time
   */
  updated: string;
}

/**
 * ApplicationNote
 * Application notes
 */
export interface ApplicationNote {
  /** Application Id */
  application_id: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  created_by: User | null;
  /** Note */
  note: string;
}

/**
 * ApplicationNoteAddRequest
 * Request to add a note to an application
 */
export interface ApplicationNoteAddRequest {
  /** Note */
  note: string;
}

/**
 * ApplicationNotes
 * Response returning the notes on an application
 */
export interface ApplicationNotes {
  /** Notes */
  notes: ApplicationNote[];
}

/**
 * ApplicationPhaseEnum
 * Enumeration of application phases. Each incentive application may have
 * 1 or more phases, each of which may have a different (likely overlapping)
 * set of requirements.
 */
export enum ApplicationPhaseEnum {
  EligibilityCheck = "eligibility_check",
  Reservation = "reservation",
  Submission = "submission",
}

/**
 * ApplicationPhaseReady
 * Represents a single application/phase
 */
export interface ApplicationPhaseReady {
  /** Schema for an incentive application. */
  application: IncentiveApplication;
  /** Is Ready */
  is_ready: boolean;
  /**
   * Enumeration of application phases. Each incentive application may have
   * 1 or more phases, each of which may have a different (likely overlapping)
   * set of requirements.
   */
  phase_name: ApplicationPhaseEnum;
}

/**
 * ApplicationPhaseStatusEnum
 * The status of an application phase for a particular project
 *
 * https://rock-rabbit.atlassian.net/wiki/spaces/RockRabbit/pages/150077441/Application+Submission#Statuses
 */
export enum ApplicationPhaseStatusEnum {
  UserInput = "user_input",
  Submitting = "submitting",
  Submitted = "submitted",
  Approved = "approved",
  Exception = "exception",
  PaymentProcessing = "payment_processing",
  Paid = "paid",
}

/**
 * ApplicationReferences
 * Application external references
 */
export interface ApplicationReferences {
  /** Application Id */
  application_id: string;
  /** References */
  references: object;
}

/** Body_add_certification_to_contractor_api_v1_contractors__contractor_id__certification__certification_id__put */
export interface BodyAddCertificationToContractorApiV1ContractorsContractorIdCertificationCertificationIdPut {
  /**
   * Doc
   * @format binary
   */
  doc: File;
}

/** Body_export_project_api_v1_projects__project_id__export_post */
export interface BodyExportProjectApiV1ProjectsProjectIdExportPost {
  /** Email */
  email?: string | null;
}

/** Body_get_active_snapshot_api_v1_internal_get_active_snapshot_post */
export interface BodyGetActiveSnapshotApiV1InternalGetActiveSnapshotPost {
  /** Password */
  password: string;
}

/** Body_invite_to_project_api_v1_projects__project_id__invite_post */
export interface BodyInviteToProjectApiV1ProjectsProjectIdInvitePost {
  /** Phone Number */
  phone_number?: string;
}

/** Body_list_incentives_api_v1_incentives_list_post */
export interface BodyListIncentivesApiV1IncentivesListPost {
  /**
   * Limit
   * @default 10
   */
  limit?: number;
  /**
   * Offset
   * @default 0
   */
  offset?: number;
  /** Query used to search for incentives. */
  request: IncentiveSearchQuery;
}

/** Body_load_snapshot_api_v1_internal_load_snapshot__snapshot_version__post */
export interface BodyLoadSnapshotApiV1InternalLoadSnapshotSnapshotVersionPost {
  /** Password */
  password: string;
  /** Skip Version Check */
  skip_version_check: boolean;
}

/** Body_login_access_token_api_v1_login_access_token_post */
export interface BodyLoginAccessTokenApiV1LoginAccessTokenPost {
  /** Client Id */
  client_id?: string | null;
  /** Client Secret */
  client_secret?: string | null;
  /** Grant Type */
  grant_type?: string | null;
  /** Password */
  password: string;
  /**
   * Scope
   * @default ""
   */
  scope?: string;
  /** Username */
  username: string;
}

/** Body_reset_password_api_v1_reset_password__post */
export interface BodyResetPasswordApiV1ResetPasswordPost {
  /** New Password */
  new_password: string;
  /** Token */
  token: string;
}

/** Body_respond_to_invite_api_v1_projects_invite__token__respond_post */
export interface BodyRespondToInviteApiV1ProjectsInviteTokenRespondPost {
  /** Accept Invite */
  accept_invite?: boolean;
}

/** Body_upload_checklist_item_file_api_v1_projects__project_id__checklist__item_id__upload_post */
export interface BodyUploadChecklistItemFileApiV1ProjectsProjectIdChecklistItemIdUploadPost {
  /**
   * Accuracy
   * Accuracy of the latitude and longitude in meters
   */
  accuracy?: number;
  /**
   * Altitude
   * Altitude in meters above sea level
   */
  altitude?: number;
  /**
   * Altitude Accuracy
   * Accuracy of the altitude in meters
   */
  altitude_accuracy?: number;
  /**
   * File
   * @format binary
   */
  file: File;
  /**
   * Latitude
   * Latitude in degrees (-90 to 90)
   */
  latitude?: number;
  /**
   * Longitude
   * Longitude in degrees (-180 to 180)
   */
  longitude?: number;
}

/** Body_upload_image_api_v1_files_upload_image_post */
export interface BodyUploadImageApiV1FilesUploadImagePost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** BundleAmount */
export interface BundleAmount {
  amount_type: AmountTypeEnum;
  /** Value */
  value: number;
}

/** BundleExportRequest */
export interface BundleExportRequest {
  /** Email */
  email: string;
}

/**
 * Business
 * Schema for a business.
 */
export interface Business {
  /** Address */
  address?: string | null;
  /** Email */
  email?: string | null;
  /** Id */
  id: string;
  /** Name */
  name: string;
  /** Phone */
  phone?: string | null;
}

/** Certification */
export interface Certification {
  /** Id */
  id: string;
  /** Logo Url */
  logo_url: string | null;
  /** Name */
  name: string;
}

/** ChecklistItemEventEnum */
export enum ChecklistItemEventEnum {
  SignatureDocumentCreated = "signature_document_created",
  RequestedSignature = "requested_signature",
  Signed = "signed",
}

/** ChecklistItemFormFieldGrouping */
export interface ChecklistItemFormFieldGrouping {
  /** Form Field Ids */
  form_field_ids: string[];
  /** Name */
  name: string;
}

/** ChecklistItemFormFieldTypeEnum */
export enum ChecklistItemFormFieldTypeEnum {
  Text = "text",
  Number = "number",
  Date = "date",
  Phone = "phone",
  SingleChoice = "single_choice",
  MultiChoice = "multi_choice",
  Boolean = "boolean",
}

/** ChecklistItemStageEnum */
export enum ChecklistItemStageEnum {
  PreReservation = "pre_reservation",
  PreInstall = "pre_install",
  PostInstall = "post_install",
  PostInspection = "post_inspection",
}

/** ChecklistItemStatusEnum */
export enum ChecklistItemStatusEnum {
  Initial = "initial",
  InProgress = "in_progress",
  PendingExternalAction = "pending_external_action",
  Completed = "completed",
}

/** ChecklistItemTypeEnum */
export enum ChecklistItemTypeEnum {
  SinglePhoto = "single_photo",
  MultiplePhotos = "multiple_photos",
  SinglePhotoOcr = "single_photo_ocr",
  SingleDocument = "single_document",
  MultipleDocuments = "multiple_documents",
  SingleDocumentOcr = "single_document_ocr",
  FileForm = "file_form",
  Form = "form",
  Signature = "signature",
}

/**
 * ContactMethodEnum
 * Represents the preferred contact method of a user.
 */
export enum ContactMethodEnum {
  Email = "email",
  Phone = "phone",
  Text = "text",
}

/** Contractor */
export interface Contractor {
  /** Address */
  address: string | null;
  /** Certifications */
  certifications: Certification[];
  /** Google Business Id */
  google_business_id: string | null;
  /** Id */
  id: string;
  /** Is Claimed */
  is_claimed: boolean;
  /** License Number */
  license_number: string;
  /** Logo Url */
  logo_url: string | null;
  /** Name */
  name: string;
  /** Programs */
  programs: ContractorProgram[];
  /** Service Areas */
  service_areas: ContractorGeography[];
  /** Services Offered */
  services_offered: ServiceType[];
  /** Enumeration of supported US states. */
  state: SupportedUSStatesEnum;
}

/** ContractorGeography */
export interface ContractorGeography {
  /** City */
  city: string | null;
  /** Country */
  country: string;
  /** County */
  county: string | null;
  /** Id */
  id: string;
  /** State */
  state: string | null;
}

/** ContractorGoogleID */
export interface ContractorGoogleID {
  contractor: Contractor;
  /** Google Place Id */
  google_place_id: string | null;
}

/**
 * ContractorProgram
 * A program that a contractor can participate in to be eligible for certain benefits.
 *
 * For example, TECH and GoGreen Financing.
 */
export interface ContractorProgram {
  /** Id */
  id: string;
  /** Logo Url */
  logo_url: string | null;
  /** Name */
  name: string;
}

/** ContractorRegistryEntryRequest */
export interface ContractorRegistryEntryRequest {
  /**
   * Address
   * Contractor address
   */
  address: string;
  /**
   * License Expiration Date
   * Contractor license expiration date
   */
  license_expiration_date?: string | null;
  /**
   * License Number
   * Contractor license number
   */
  license_number: string;
  /**
   * Name
   * Contractor name
   */
  name: string;
  /**
   * Phone Number
   * Contractor phone number
   */
  phone_number: string;
  /** State where the license was issued */
  state: SupportedUSStatesEnum;
}

/** ContractorRegistryEntryResponse */
export interface ContractorRegistryEntryResponse {
  /**
   * Address
   * Contractor address
   */
  address?: string | null;
  /**
   * Id
   * Contractor ID
   */
  id?: string | null;
  /**
   * Is Claimed
   * has this contractor org been claimed by a user
   */
  is_claimed: boolean;
  /**
   * License Expiration Date
   * Contractor license expiration date
   */
  license_expiration_date?: string | null;
  /**
   * License Number
   * Contractor license number
   */
  license_number: string;
  /**
   * Name
   * Contractor name
   */
  name?: string | null;
  /**
   * Phone Number
   * Contractor phone number
   */
  phone_number?: string | null;
  /** State where the license was issued */
  state: SupportedUSStatesEnum;
}

/** ContractorSelf */
export interface ContractorSelf {
  /** Address */
  address: string | null;
  /** Certifications */
  certifications: ContractorToCertification[];
  /** Google Business Id */
  google_business_id: string | null;
  /** Id */
  id: string;
  /** Is Claimed */
  is_claimed: boolean;
  /** Is Google Business Verified */
  is_google_business_verified: boolean | null;
  /** License Number */
  license_number: string;
  /** Logo Url */
  logo_url: string | null;
  /** Name */
  name: string;
  /** Programs */
  programs: ContractorToProgram[];
  /** Service Areas */
  service_areas: ContractorGeography[];
  /** Services Offered */
  services_offered: ServiceType[];
  /** Enumeration of supported US states. */
  state: SupportedUSStatesEnum;
}

/** ContractorToCertification */
export interface ContractorToCertification {
  certification: Certification;
  /** Is Verified */
  is_verified: boolean;
}

/** ContractorToCertificationAssociation */
export interface ContractorToCertificationAssociation {
  certification: Certification;
  contractor: Contractor;
  /** Document Url */
  document_url: string;
  /** Id */
  id: string;
  /** Is Verified */
  is_verified: boolean;
}

/** ContractorToProgram */
export interface ContractorToProgram {
  /** Is Verified */
  is_verified: boolean;
  /**
   * A program that a contractor can participate in to be eligible for certain benefits.
   *
   * For example, TECH and GoGreen Financing.
   */
  program: ContractorProgram;
}

/** ContractorToProgramAssociation */
export interface ContractorToProgramAssociation {
  contractor: Contractor;
  /** Id */
  id: string;
  /** Is Verified */
  is_verified: boolean;
  /**
   * A program that a contractor can participate in to be eligible for certain benefits.
   *
   * For example, TECH and GoGreen Financing.
   */
  program: ContractorProgram;
}

/** ContractorWithUsers */
export interface ContractorWithUsers {
  contractor: ContractorSelf;
  /** Users */
  users: User[];
}

/** CreateTeamRequest */
export interface CreateTeamRequest {
  /**
   * Name
   * The team's name
   */
  name: string;
}

/**
 * CreateUploadRequest
 * A request to store a new file for a user
 */
export interface CreateUploadRequest {
  /**
   * Content Type
   * Content type of the file
   */
  content_type: string;
  /**
   * Last Modified
   * Last modified date of the file
   * @format date-time
   */
  last_modified: string;
  /**
   * Original Filename
   * Original filename of the file
   */
  original_filename: string;
}

/** CreateUserRequest */
export interface CreateUserRequest {
  /**
   * Communications Accepted
   * Consent to receive marketing emails
   * @default false
   */
  communications_accepted?: boolean | null;
  /**
   * Email
   * Email
   * @format email
   */
  email: string;
  /**
   * First Name
   * First name
   */
  first_name: string;
  /**
   * Last Name
   * Last name
   */
  last_name: string;
  /**
   * Password
   * Password
   */
  password?: string | null;
  /**
   * Phone Number
   * Phone number
   */
  phone_number?: string | null;
  /**
   * Rr Invite Token
   * Invite token
   */
  rr_invite_token?: string | null;
  /** User type */
  user_type?: UserType | null;
}

/** DuctStatusEnum */
export enum DuctStatusEnum {
  Ducted = "ducted",
  Ductless = "ductless",
}

/** Equipment */
export interface Equipment {
  /** Brand */
  brand: string;
  /** Details */
  details: EquipmentDetails[] | null;
  /** Id */
  id: string | null;
  /** Model */
  model: string;
}

/** EquipmentDetails */
export interface EquipmentDetails {
  /** Field */
  field: string;
  /** Value */
  value: string[];
}

/** ErrorResponse */
export interface ErrorResponse {
  id?: NamedErrorEnum | null;
  /** Locize Key */
  locize_key?: string | null;
  /** Message */
  message?: string | null;
}

/** ExternalReference */
export interface ExternalReference {
  /** Text */
  text: string;
  /** Url */
  url: string | null;
}

/** FeedbackRequest */
export interface FeedbackRequest {
  /** Body */
  body: string;
  /**
   * Email
   * @format email
   */
  email: string;
}

/**
 * FieldValidationFailure
 * Used to raise errors during submission or validation of submission
 */
export interface FieldValidationFailure {
  /** Checklist Item */
  checklist_item?: string | null;
  /** Message */
  message: string;
  /**
   * Severity
   * @default "error"
   */
  severity?: string;
}

/** FuelTypeEnum */
export enum FuelTypeEnum {
  Electricity = "electricity",
  NaturalGas = "natural_gas",
  Diesel = "diesel",
  Gasoline = "gasoline",
  Propane = "propane",
  HeatingOil = "heating_oil",
  Coal = "coal",
  Wood = "wood",
  Biomass = "biomass",
  Hydrogen = "hydrogen",
  Solar = "solar",
  Wind = "wind",
  Geothermal = "geothermal",
  Kerosene = "kerosene",
}

/** FundRecipientTypeEnum */
export enum FundRecipientTypeEnum {
  Homeowner = "homeowner",
  Renter = "renter",
  Contractor = "contractor",
  Landlord = "landlord",
  AssignedRecipient = "assigned_recipient",
}

/** GenericResponse */
export interface GenericResponse {
  /** Message */
  message?: string | null;
  /** Success */
  success: boolean;
}

/** Geography */
export interface Geography {
  /** Address */
  address?: string | null;
  /** City */
  city?: string | null;
  /** Country */
  country?: string | null;
  /** County */
  county?: string | null;
  /** Lat */
  lat?: number | null;
  /** Lng */
  lng?: number | null;
  /** Postal City */
  postal_city?: string | null;
  /** Postal Code */
  postal_code?: string | null;
  /** State */
  state?: string | null;
}

/** GetPhaseUpdatesResponse */
export interface GetPhaseUpdatesResponse {
  /** Updates */
  updates: IncentiveApplicationPhaseEvent[];
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HVACCoolingTypeEnum */
export enum HVACCoolingTypeEnum {
  Central = "central",
  Portable = "portable",
  DuctlessMiniSplit = "ductless_mini_split",
  FloorMounted = "floor_mounted",
  Window = "window",
  None = "None",
}

/** HVACHeatingTypeEnum */
export enum HVACHeatingTypeEnum {
  ForcedAir = "forced_air",
  AirSourceHeatPump = "air_source_heat_pump",
  OverheadFurnace = "overhead_furnace",
  WallFurnace = "wall_furnace",
  FloorFurnace = "floor_furnace",
  Baseboard = "baseboard",
  Ceiling = "ceiling",
  CentralFurnace = "central_furnace",
  RadiantHeating = "radiant_heating",
  FireplaceFreestandingStove = "fireplace_freestanding_stove",
}

/** HVACZoneEnum */
export enum HVACZoneEnum {
  SingleZone = "single_zone",
  MultiZone = "multi_zone",
}

/** HeatPumpTypeEnum */
export enum HeatPumpTypeEnum {
  AirToAir = "air_to_air",
  AirToWater = "air_to_water",
}

/**
 * HoCoSplit
 * Schema for a project incentive's contractor/homeowner split
 */
export interface HoCoSplit {
  /**
   * Ho Payment Pct
   * Percentage of the incentive paid to the homeowner
   * @min 0
   * @max 100
   */
  ho_payment_pct: number;
  /**
   * Incentive Id
   * ID of the incentive
   */
  incentive_id: string;
}

/** IncentiveAddon */
export interface IncentiveAddon {
  /** Additional Notes */
  additional_notes?: string[] | null;
  /**
   * Amount
   * The amount of the incentive.
   * @deprecated
   */
  amount: number | null;
  /**
   * Amount Unit
   * The unit of the amount of the incentive.
   * @deprecated
   */
  amount_unit: string | null;
  /**
   * Amounts
   * The amounts of the incentive.
   */
  amounts?: IncentiveAmount[];
  /** Description */
  description?: string | null;
  /** Eligibility Requirements */
  eligibility_requirements: IncentiveEligibilityRequirement[];
  /** Name */
  name: string;
}

/** IncentiveAlert */
export interface IncentiveAlert {
  /** Details */
  details: string;
  /** Effective Date */
  effective_date?: string | null;
  /** Expiration Date */
  expiration_date?: string | null;
  /** Url */
  url?: string | null;
}

/** IncentiveAmount */
export interface IncentiveAmount {
  /** Amount */
  amount: number | null;
  amount_type: AmountTypeEnum;
  end_cutoff: IncentiveAmountCutoff | null;
  start_cutoff: IncentiveAmountCutoff | null;
}

/** IncentiveAmountCutoff */
export interface IncentiveAmountCutoff {
  /**
   * Date
   * @format date
   */
  date: string;
  /** When an incentive amount has a cutoff date, this indicates what must be done by that date. */
  event: IncentiveAmountCutoffEvent;
}

/**
 * IncentiveAmountCutoffEvent
 * When an incentive amount has a cutoff date, this indicates what must be done by that date.
 */
export enum IncentiveAmountCutoffEvent {
  Application = "application",
  CurrentDate = "current_date",
  EquipmentPurchase = "equipment_purchase",
  Installation = "installation",
  Reservation = "reservation",
  Sale = "sale",
}

/**
 * IncentiveApplication
 * Schema for an incentive application.
 */
export interface IncentiveApplication {
  /** Amount */
  amount?: number | null;
  amount_type?: AmountTypeEnum | null;
  /** Created */
  created?: string | null;
  /**
   * Detailed Amounts
   * Map from incentive ID to list of detailed amounts
   */
  detailed_amounts: Record<string, IncentiveComputedAmount[]>;
  /** Id */
  id: string;
  /** Incentives */
  incentives?: IncentiveCard[] | null;
  /**
   * Is Submittable
   * @default false
   */
  is_submittable?: boolean | null;
  /** Name */
  name: string;
  /** Phases */
  phases: IncentiveApplicationPhase[];
  /** Project Id */
  project_id: string;
  /** Project Name */
  project_name: string;
  /** References */
  references: ExternalReference[];
  /** @deprecated */
  status: IncentiveApplicationStatusEnum;
  /**
   * Submission Form
   * @default []
   */
  submission_form?: ProjectChecklistFormField[] | null;
  update_notification: IncentiveApplicationPhaseEvent | null;
  /** Updated */
  updated?: string | null;
}

/**
 * IncentiveApplicationDetails
 * Schema for an incentive application with additional details.
 */
export interface IncentiveApplicationDetails {
  /** Amount */
  amount?: number | null;
  amount_type?: AmountTypeEnum | null;
  /** Created */
  created?: string | null;
  /**
   * Detailed Amounts
   * Map from incentive ID to list of detailed amounts
   */
  detailed_amounts: Record<string, IncentiveComputedAmount[]>;
  /** Id */
  id: string;
  /** Incentives */
  incentives?: IncentiveCard[] | null;
  /**
   * Is Submittable
   * @default false
   */
  is_submittable?: boolean | null;
  /**
   * Logs
   * @default []
   */
  logs?: IncentiveApplicationLogEntry[] | null;
  /** Name */
  name: string;
  /** Phases */
  phases: IncentiveApplicationPhase[];
  /** Project Id */
  project_id: string;
  /** Project Name */
  project_name: string;
  /** References */
  references: ExternalReference[];
  /** @deprecated */
  status: IncentiveApplicationStatusEnum;
  /**
   * Submission Form
   * @default []
   */
  submission_form?: ProjectChecklistFormField[] | null;
  update_notification: IncentiveApplicationPhaseEvent | null;
  /** Updated */
  updated?: string | null;
}

/**
 * IncentiveApplicationLogEntry
 * Schema for an incentive application log entry.
 */
export interface IncentiveApplicationLogEntry {
  /** Enum for an incentive application log level. */
  level: IncentiveApplicationLogLevelEnum;
  /** Message */
  message: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/**
 * IncentiveApplicationLogLevelEnum
 * Enum for an incentive application log level.
 */
export enum IncentiveApplicationLogLevelEnum {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

/** IncentiveApplicationPhase */
export interface IncentiveApplicationPhase {
  /** Id */
  id: string;
  /**
   * Enumeration of application phases. Each incentive application may have
   * 1 or more phases, each of which may have a different (likely overlapping)
   * set of requirements.
   */
  phase: ApplicationPhaseEnum;
  /** Rr Supported */
  rr_supported: boolean;
  /**
   * The status of an application phase for a particular project
   *
   * https://rock-rabbit.atlassian.net/wiki/spaces/RockRabbit/pages/150077441/Application+Submission#Statuses
   */
  status: ApplicationPhaseStatusEnum;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
}

/** IncentiveApplicationPhaseEvent */
export interface IncentiveApplicationPhaseEvent {
  /** Application Id */
  application_id: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * The status of an application phase for a particular project
   *
   * https://rock-rabbit.atlassian.net/wiki/spaces/RockRabbit/pages/150077441/Application+Submission#Statuses
   */
  new_status: ApplicationPhaseStatusEnum;
  /** Note */
  note: string | null;
  /**
   * Enumeration of application phases. Each incentive application may have
   * 1 or more phases, each of which may have a different (likely overlapping)
   * set of requirements.
   */
  phase_name: ApplicationPhaseEnum;
  /** Project Id */
  project_id: string;
  /** User Full Name */
  user_full_name: string;
}

/** IncentiveApplicationStatusEnum */
export enum IncentiveApplicationStatusEnum {
  InProgress = "in_progress",
  Reserved = "reserved",
  ReadyToReserve = "ready_to_reserve",
  ReadyToSubmit = "ready_to_submit",
  ReadyToExport = "ready_to_export",
  Exported = "exported",
  Submitted = "submitted",
  Approved = "approved",
  Rejected = "rejected",
  NeedsAttention = "needs_attention",
}

/**
 * IncentiveApplicationSubmitRequest
 * Schema for an application submit request.
 */
export interface IncentiveApplicationSubmitRequest {
  /**
   * Form Responses
   * @default []
   */
  form_responses?: ProjectChecklistFormFieldAnswer[] | null;
  /**
   * Enumeration of application phases. Each incentive application may have
   * 1 or more phases, each of which may have a different (likely overlapping)
   * set of requirements.
   */
  type: ApplicationPhaseEnum;
}

/**
 * IncentiveApplicationSubmitResponse
 * Schema for an application submit response.
 */
export interface IncentiveApplicationSubmitResponse {
  /** Message */
  message?: string | null;
  /**
   * Success
   * @default true
   */
  success?: boolean;
}

/** IncentiveBundle */
export interface IncentiveBundle {
  address: Geography;
  /** Amounts */
  amounts: Record<string, BundleAmount | null>;
  /**
   * Detailed Amounts
   * Map from incentive ID to computed amounts for each incentive
   */
  detailed_amounts: Record<string, IncentiveComputedAmount[]>;
  equipment?: Equipment | null;
  /** Estimated Project Cost */
  estimated_project_cost: number | null;
  /**
   * Ho Co Splits
   * Incentive splits between homeowner and contractor
   */
  ho_co_splits: HoCoSplit[];
  /** Id */
  id: string;
  /** Incentives */
  incentives?: IncentiveCard[] | null;
  /** Requirements */
  requirements: IncentiveEligibilityRequirement[] | null;
  /** Wizard Session Id */
  wizard_session_id?: string | null;
}

/**
 * IncentiveBundleCreateRequest
 * Deprecated; please use wizard/complete to create a bundle from a wizard session
 * @deprecated
 */
export interface IncentiveBundleCreateRequest {
  /** Incentive Ids */
  incentive_ids: string[] | null;
  /** Wizard Session Id */
  wizard_session_id: string | null;
}

/** IncentiveBundleListIncentivesRequest */
export interface IncentiveBundleListIncentivesRequest {
  /** Incentive Types */
  incentive_types: IncentiveTypeEnum[] | null;
}

/** IncentiveBundleUpdateRequest */
export interface IncentiveBundleUpdateRequest {
  /** Equipment Id */
  equipment_id?: string | null;
  /** Estimated Project Cost */
  estimated_project_cost?: number | null;
  /** Ho Co Splits */
  ho_co_splits?: HoCoSplit[] | null;
  /** Incentive Ids */
  incentive_ids?: string[] | null;
  /** Incentive Types */
  incentive_types?: IncentiveTypeEnum[] | null;
}

/** IncentiveCard */
export interface IncentiveCard {
  /**
   * Addon Amount
   * @deprecated
   */
  addon_amount?: number | null;
  /** @deprecated */
  addon_amount_unit?: AmountTypeEnum | null;
  /**
   * Amount
   * The amount of the incentive.
   * @deprecated
   */
  amount?: number | null;
  /**
   * The unit of the amount of the incentive.
   * @deprecated
   */
  amount_unit?: AmountTypeEnum | null;
  /**
   * Amounts
   * The amounts of the incentive.
   */
  amounts: IncentiveAmount[];
  /**
   * Available Addon Ids
   * The IDs of the available addons for the incentive.
   */
  available_addon_ids?: string[];
  /**
   * Detailed Amounts
   * The detailed amounts of the incentive.
   */
  detailed_amounts: IncentiveComputedAmount[];
  /**
   * Id
   * The ID of the incentive.
   */
  id: string;
  /** The type of the incentive. */
  incentive_type: IncentiveTypeEnum;
  /**
   * Is Addon
   * Whether the incentive is an addon.
   * @default false
   */
  is_addon?: boolean;
  /**
   * Job Types
   * The job types the incentive is applicable to.
   */
  job_types?: JobTypeEnum[] | null;
  /**
   * Last Updated
   * The last updated date of the incentive.
   */
  last_updated?: string | null;
  /**
   * Max Amount
   * The maximum amount of the incentive.
   * @deprecated
   */
  max_amount?: number | null;
  /**
   * Max Amount Unit
   * The unit of the maximum amount of the incentive.
   * @deprecated
   */
  max_amount_unit?: string | null;
  /**
   * Name
   * The name of the incentive.
   */
  name: string;
  /**
   * Payment Modes
   * The payment modes of the incentive.
   */
  payment_modes?: IncentivePaymentMode[] | null;
  /**
   * Payment Notes
   * Additional payment-related notes for the incentive.
   */
  payment_notes?: string[];
  /** The provider of the incentive. */
  provider: AppSchemasIncentiveOrganization;
  /**
   * Services
   * The services the incentive is applicable to.
   */
  services?: ServiceTypeEnum[] | null;
  /** The status of the incentive. */
  status: IncentiveProgramStatusEnum;
}

/**
 * IncentiveChange
 * A change in an incentive's property. This is used to track **structured** changes to an incentive's properties.
 */
export interface IncentiveChange {
  /** Enum for incentive change types. */
  change_type: IncentiveChangeTypeEnum;
  /** New Value */
  new_value: string;
  /** Old Value */
  old_value: string | null;
}

/**
 * IncentiveChangeAlert
 * An alert about a change in an incentive's properties.
 */
export interface IncentiveChangeAlert {
  /** Changes */
  changes?: IncentiveChange[];
  /**
   * Effective Date
   * @format date-time
   */
  effective_date: string;
  incentive: IncentiveCard;
  /** Message */
  message?: string | null;
  /** Reference Url */
  reference_url?: string | null;
  /** Related Projects */
  related_projects?: ProjectCard[];
}

/**
 * IncentiveChangeTypeEnum
 * Enum for incentive change types.
 */
export enum IncentiveChangeTypeEnum {
  Uncategorized = "uncategorized",
}

/** IncentiveComputedAmount */
export interface IncentiveComputedAmount {
  /**
   * Actual Multiplier
   * The actual multiplier used to calculate computed_amount. May differ from the user_provided_multipler if the incentive has max multipler constraints
   */
  actual_multiplier?: number | null;
  /**
   * Amount
   * The base amount of money that the incentive provides
   */
  amount: number;
  /** The type of the amount (e.g., usd, usd_per_condenser) */
  amount_type: AmountTypeEnum;
  /**
   * Computed Amount
   * The actual amount of money this incentive provides. This is the product of amount and actual_multiplier
   */
  computed_amount: number;
  /**
   * Ho Payment Pct
   * The percentage of the incentive that will be passed through to the homeowner
   */
  ho_payment_pct?: number | null;
  /**
   * Incentive Max Amount Per Account
   * The maximum amount of money that this incentive can provide
   */
  incentive_max_amount_per_account?: number | null;
  /** The way that the money is delivered (e.g., rebate, loan, grant) */
  incentive_type: IncentiveTypeEnum;
  /**
   * Max Multiplier
   * The maximum multiplier that can be applied to the amount
   */
  max_multiplier?: number | null;
  /**
   * Min Ho Payment Pct
   * The minimum percentage of the incentive that must be passed through to the homeowner
   */
  min_ho_payment_pct?: number | null;
  /**
   * Passthrough Required
   * Whether the incentive requires a passthrough to the homeowner
   * @default false
   */
  passthrough_required?: boolean;
  /**
   * User Provided Multipler
   * Deprecated; use 'user_provided_multiplier' instead
   * @deprecated
   */
  user_provided_multipler: number | null;
  /**
   * User Provided Multiplier
   * The amount multiplier provided by the user - extracted from project context
   */
  user_provided_multiplier: number | null;
}

/** IncentiveDetails */
export interface IncentiveDetails {
  /**
   * Additional Notes
   * The list of additional notes for the incentive.
   */
  additional_notes?: string[] | null;
  /**
   * Additional Requirements
   * The additional requirements for the incentive.
   */
  additional_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Addon Amount
   * @deprecated
   */
  addon_amount?: number | null;
  /** @deprecated */
  addon_amount_unit?: AmountTypeEnum | null;
  /** The requirements for the addon. */
  addon_requirements: IncentiveRequirements;
  /**
   * Addons
   * @deprecated
   */
  addons?: IncentiveAddon[];
  /**
   * Alerts
   * The list of alerts for the incentive.
   */
  alerts?: IncentiveAlert[];
  /**
   * Amount
   * The amount of the incentive.
   * @deprecated
   */
  amount?: number | null;
  /**
   * The unit of the amount of the incentive.
   * @deprecated
   */
  amount_unit?: AmountTypeEnum | null;
  /**
   * Amounts
   * The amounts of the incentive.
   */
  amounts: IncentiveAmount[];
  /**
   * Available Addon Ids
   * The IDs of the available addons for the incentive.
   */
  available_addon_ids?: string[];
  /**
   * Detailed Amounts
   * The detailed amounts of the incentive.
   */
  detailed_amounts: IncentiveComputedAmount[];
  /**
   * Equipment Requirements
   * The equipment requirements for the incentive.
   */
  equipment_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Exclusions
   * The exclusions for the incentive.
   */
  exclusions?: IncentiveEligibilityRequirement[];
  /**
   * General Requirements
   * The general requirements for the incentive.
   */
  general_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Id
   * The ID of the incentive.
   */
  id: string;
  /** The type of the incentive. */
  incentive_type: IncentiveTypeEnum;
  /**
   * Income Requirements
   * The income requirements for the incentive.
   */
  income_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Is Addon
   * Whether the incentive is an addon.
   * @default false
   */
  is_addon?: boolean;
  /**
   * Job Types
   * The job types the incentive is applicable to.
   */
  job_types?: JobTypeEnum[] | null;
  /**
   * Last Updated
   * The last updated date of the incentive.
   */
  last_updated?: string | null;
  /**
   * Max Amount
   * The maximum amount of the incentive.
   * @deprecated
   */
  max_amount?: number | null;
  /**
   * Max Amount Unit
   * The unit of the maximum amount of the incentive.
   * @deprecated
   */
  max_amount_unit?: string | null;
  /**
   * Name
   * The name of the incentive.
   */
  name: string;
  /**
   * Payment Modes
   * The payment modes of the incentive.
   */
  payment_modes?: IncentivePaymentMode[] | null;
  /**
   * Payment Notes
   * Additional payment-related notes for the incentive.
   */
  payment_notes?: string[];
  /**
   * Program Id
   * The ID of the program the incentive is part of.
   */
  program_id?: string | null;
  /**
   * Program Name
   * The name of the program the incentive is part of.
   */
  program_name?: string | null;
  /** The provider of the incentive. */
  provider: AppSchemasIncentiveOrganization;
  /**
   * References
   * The list of references for the incentive.
   */
  references?: IncentiveReference[];
  /**
   * Services
   * The services the incentive is applicable to.
   */
  services?: ServiceTypeEnum[] | null;
  /** The status of the incentive. */
  status: IncentiveProgramStatusEnum;
  /**
   * Timeline
   * The status history of the incentive.
   */
  timeline?: IncentiveStatusHistory[] | null;
}

/**
 * IncentiveDocRequirement
 * A requirement for a document to be submitted for an incentive.
 */
export interface IncentiveDocRequirement {
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Is Required */
  is_required: boolean;
  /** Name */
  name: string;
  requirement_type: IncentiveDocTypeEnum;
}

/** IncentiveDocTypeEnum */
export enum IncentiveDocTypeEnum {
  SinglePhoto = "single_photo",
  MultiplePhotos = "multiple_photos",
  SinglePhotoOcr = "single_photo_ocr",
  SingleDocument = "single_document",
  MultipleDocuments = "multiple_documents",
  SingleDocumentOcr = "single_document_ocr",
  FileForm = "file_form",
  Form = "form",
  Signature = "signature",
  Text = "text",
  Number = "number",
  Date = "date",
  Phone = "phone",
  SingleChoice = "single_choice",
  MultiChoice = "multi_choice",
  Boolean = "boolean",
}

/** IncentiveEligibilityRequirement */
export interface IncentiveEligibilityRequirement {
  /** Requirement Type */
  requirement_type: string;
  /** Value */
  value?: string[] | null;
}

/** IncentivePaymentMode */
export interface IncentivePaymentMode {
  /**
   * Max Usd
   * The maximum USD amount below which this payment mode is applied.
   */
  max_usd?: number | null;
  /**
   * Min Usd
   * The minimum USD amount above which this payment mode is applied.
   */
  min_usd?: number | null;
  /**
   * Recipients
   * The possible recipients of the payment mode.
   */
  recipients?: FundRecipientTypeEnum[] | null;
  /** The payment type utilized by this payment mode. */
  type: PaymentTypeEnum;
}

/** IncentiveProgramStatusEnum */
export enum IncentiveProgramStatusEnum {
  AcceptingApplications = "accepting_applications",
  FullyReserved = "fully_reserved",
  ResumingSoon = "resuming_soon",
  ChangingSoon = "changing_soon",
  ComingSoon = "coming_soon",
  Closed = "closed",
}

/** IncentiveReference */
export interface IncentiveReference {
  type: IncentiveReferenceTypeEnum;
  /** Value */
  value: string;
}

/** IncentiveReferenceTypeEnum */
export enum IncentiveReferenceTypeEnum {
  ProgramUrl = "program_url",
  PaymentAssignmentAppUrl = "payment_assignment_app_url",
  TermsAndConditionsUrl = "terms_and_conditions_url",
}

/** IncentiveReportReq */
export interface IncentiveReportReq {
  /**
   * Incentive Ids
   * The list of incentive IDs. If provided, `query` will be ignored.
   */
  incentive_ids?: string[] | null;
  /** The search query. If `incentive_ids` are provided, `query` will be ignored. */
  query?: IncentiveSearchQuery | null;
  /**
   * Recipient
   * Email of the report recipient.
   */
  recipient?: string | null;
  /** The target recipient type of the report. */
  target: IncentiveReportTargetEnum;
}

/** IncentiveReportRes */
export interface IncentiveReportRes {
  /** Id */
  id: string;
  /** Recipient */
  recipient?: string | null;
  status: IncentiveReportStatusEnum;
  target: IncentiveReportTargetEnum;
  /** Visitor Count */
  visitor_count: number;
}

/** IncentiveReportStatusEnum */
export enum IncentiveReportStatusEnum {
  Submitted = "submitted",
  InProgress = "in_progress",
  Completed = "completed",
  Failed = "failed",
}

/** IncentiveReportTargetEnum */
export enum IncentiveReportTargetEnum {
  GeneralUser = "general_user",
  Provider = "provider",
}

/** IncentiveRequirements */
export interface IncentiveRequirements {
  /**
   * Additional Requirements
   * The additional requirements for the incentive.
   */
  additional_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Equipment Requirements
   * The equipment requirements for the incentive.
   */
  equipment_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Exclusions
   * The exclusions for the incentive.
   */
  exclusions?: IncentiveEligibilityRequirement[];
  /**
   * General Requirements
   * The general requirements for the incentive.
   */
  general_requirements?: IncentiveEligibilityRequirement[];
  /**
   * Income Requirements
   * The income requirements for the incentive.
   */
  income_requirements?: IncentiveEligibilityRequirement[];
}

/**
 * IncentiveSearchQuery
 * Query used to search for incentives.
 */
export interface IncentiveSearchQuery {
  /** The EER2 range to filter by. If None, all incentives are shown regardless of EER2 requirements. Applicable to HVAC incentives only. */
  hvac_eer2?: NumericRange | null;
  /** The HSPF2 range to filter by. If None, all incentives are shown regardless of HSPF2 requirements. Applicable to HVAC incentives only. */
  hvac_hspf2?: NumericRange | null;
  /** The required SEER2 range to filter by. Applicable to HVAC incentives only. */
  hvac_seer2?: NumericRange | null;
  /**
   * Range Is Gt 24 Inch
   * Whether the range is more than 24 inches.
   */
  range_is_gt_24_inch?: boolean | null;
  /**
   * Applicant Types
   * The types of required applicant to filter by. If empty, all incentives are shown regardless of applicant type.
   */
  applicant_types?: string[];
  /**
   * Contractor Networks
   * The contractor networks to filter by. Defaults to all contractor networks.
   */
  contractor_networks?: string[];
  /**
   * Diy Allowed
   * Whether a DIY project is allowed for the incentive. If None, all incentives are shown regardless of DIY allowance.
   */
  diy_allowed?: boolean | null;
  /**
   * Electricity Distributors
   * The electricity distributor (e.g. Utility) requirements to filter by. If empty, all incentives are shown regardless of required electricity distributor.
   */
  electricity_distributors?: string[];
  /**
   * Electricity Suppliers
   * The electricity supplier (e.g. Community Energy Agency) requirements to filter by. If empty, all incentives are shown regardless of required electricity supplier.
   */
  electricity_suppliers?: string[];
  /**
   * Existing Ac
   * Whether the existing equipment is an AC. If None, all incentives are shown regardless .
   */
  existing_ac?: boolean | null;
  /**
   * Existing Ac Types
   * The existing AC types to filter by. Defaults to all AC types. Applicable to HVAC incentives only.
   */
  existing_ac_types?: HVACCoolingTypeEnum[];
  /**
   * Existing Duct Statuses
   * The existing duct statuses to filter by. Defaults to all duct statuses. Applicable to HVAC incentives only.
   */
  existing_duct_statuses?: DuctStatusEnum[];
  /**
   * Existing Equipment Is Heat Pump
   * Whether the existing equipment is a heat pump. If None, all incentives are shown regardless of existing equipment type.
   */
  existing_equipment_is_heat_pump?: boolean | null;
  /**
   * Existing Fuel Types
   * The existing fuel types to filter by. Defaults to all fuel types.
   */
  existing_fuel_types?: FuelTypeEnum[];
  /**
   * Existing Heating Types
   * The existing heating types to filter by. Defaults to all heating types. Applies to HVAC incentives only.
   */
  existing_heating_types?: HVACHeatingTypeEnum[];
  /** The existing HVAC age range to filter by. If None, all incentives are shown regardless of existing HVAC age. */
  existing_hvac_age?: NumericRange | null;
  /** The existing tank capacity range to filter by. If None, all incentives are shown regardless of existing tank capacity are shown. Applicable to HPWH incentives only. */
  existing_tank_capacity?: NumericRange | null;
  /**
   * Existing Wh Types
   * The existing water heater types to filter by. Defaults to all water heater types. Applicable to HPWH incentives only.
   */
  existing_wh_types?: WaterHeaterTypeEnum[];
  /**
   * Gas Providers
   * The gas provider requirements to filter by. If empty, all incentives are shown regardless of required gas provider.
   */
  gas_providers?: string[];
  /** The geography to filter by */
  geography?: Geography | null;
  /** The total income of the household. */
  household_income?: NumericRange | null;
  /** The number of people in the household. */
  household_size?: NumericRange | null;
  /** The HPWH amperage range to filter by. If None, all incentives are shown regardless of amperage requirements. Applicable to HPWH incentives only. */
  hpwh_amps?: NumericRange | null;
  /** The UEF range to filter by. If None, all incentives are shown regardless of UEF requirements. Applicable to HPWH incentives only. */
  hpwh_uef?: NumericRange | null;
  /** The HPWH voltage range to filter by. If None, all incentives are shown regardless of voltage requirements. Applicable to HPWH incentives only. */
  hpwh_volts?: NumericRange | null;
  /** The EER range to filter by. If None, all incentives are shown regardless of EER requirements. Applicable to HVAC incentives only. */
  hvac_eer?: NumericRange | null;
  /** The HSPF range to filter by. If None, all incentives are shown regardless of HSPF requirements. Applicable to HVAC incentives only. */
  hvac_hspf?: NumericRange | null;
  /** The required SEER range to filter by. Applicable to HVAC incentives only. */
  hvac_seer?: NumericRange | null;
  /**
   * Incentive Types
   * The incentive types to filter by. Defaults to all incentive types.
   */
  incentive_types?: string[];
  /**
   * Include Equity Incentives
   * Whether to include equity incentives in the result.
   */
  include_equity_incentives?: boolean | null;
  /**
   * Income Qualification
   * The types of required income qualification to filter by. If empty, all incentives will be surfaced, regardless of income qualification.
   */
  income_qualification?: string[];
  /**
   * Income Qualified Program Participation
   * Low income programs that the user is participating in.
   */
  income_qualified_program_participation?: LowIncomeProgramEnum[];
  /**
   * New Duct Statuses
   * The new duct statuses to filter by. Defaults to all duct statuses. Applicable to HVAC incentives only.
   */
  new_duct_statuses?: DuctStatusEnum[];
  /**
   * New Heating Primary Heat Pump
   * Whether the new heating system is a primary heat pump. If None, all incentives are shown regardless of primary heat pump.
   */
  new_heating_primary_heat_pump?: boolean | null;
  /** The new heating tons range to filter by. If None, all incentives are shown regardless of new heating tons. Applicable to HVAC incentives only. */
  new_heating_tons?: NumericRange | null;
  /**
   * New Hpwh Is Split
   * Whether the HPWH is a split system. Applicable to HPWH incentives only.
   */
  new_hpwh_is_split?: boolean | null;
  /**
   * New Hvac Dual Fuel Allowed
   * Whether dual fuel is allowed for the new HVAC system. If None, all incentives are shown regardless of dual fuel allowance.
   */
  new_hvac_dual_fuel_allowed?: boolean | null;
  /**
   * New Hvac Heat Pump Types
   * The new HVAC heat pump types to filter by. Defaults to all heat pump types. Applicable to HVAC incentives only.
   */
  new_hvac_heat_pump_types?: HeatPumpTypeEnum[];
  /**
   * New Hvac Is Cold Climate
   * Whether the new HVAC system is a cold climate heat pump.
   */
  new_hvac_is_cold_climate?: boolean | null;
  /**
   * New Hvac Is Dual Fuel
   * Whether the new HVAC system is dual fuel.
   */
  new_hvac_is_dual_fuel?: boolean | null;
  /**
   * New Hvac Is High Velocity
   * Whether the new HVAC system is high velocity.
   */
  new_hvac_is_high_velocity?: boolean | null;
  /**
   * New Hvac Is Mini Split
   * Whether the new HVAC system is a mini split.
   */
  new_hvac_is_mini_split?: boolean | null;
  /** The number of condensers for the new HVAC system. */
  new_hvac_num_condensers?: NumericRange | null;
  /**
   * New Hvac Packaged
   * Whether the new HVAC system is packaged.
   */
  new_hvac_packaged?: boolean | null;
  /**
   * New Hvac Zones
   * The new HVAC zones to filter by. Defaults to all HVAC zones. Applicable to HVAC incentives only.
   */
  new_hvac_zones?: HVACZoneEnum[];
  /**
   * New Stove Has Battery
   * Whether the stove has an integrated battery.
   */
  new_stove_has_battery?: boolean | null;
  /**
   * New Stove Is Dual Fuel
   * Whether the new stove is dual-fuel (e.g. induction cooktop, gas oven).
   */
  new_stove_is_dual_fuel?: boolean | null;
  /**
   * New System Is Ducted
   * Whether the new system is ducted.
   */
  new_system_is_ducted?: boolean | null;
  /** The number of bathrooms to filter by. This is also used for calculating JA-13 FHR requirement for HPWH incentives. */
  num_bathrooms?: NumericRange | null;
  /** The number of bedrooms to filter by. This is also used for calculating JA-13 FHR requirement for HPWH incentives. */
  num_bedrooms?: NumericRange | null;
  /**
   * Permit Required
   * Whether a permit is required for the incentive. If None, all incentives are shown regardless of permit requirement.
   */
  permit_required?: boolean | null;
  /**
   * Program Status
   * The program statuses to filter by. Defaults to all program statuses.
   */
  program_status?: IncentiveProgramStatusEnum[];
  /**
   * Project Types
   * The project types to filter by. Defaults to all project types.
   */
  project_types?: ProjectTypeEnum[];
  /**
   * Property Types
   * The property types to filter by. Defaults to all property types.
   */
  property_types?: PropertyTypeEnum[];
  /**
   * Providers
   * The incentive program providers to filter by. Defaults to all providers.
   */
  providers?: string[];
  /**
   * Requires Dr Enrollment
   * Whether a Demand Response (DR) program enrollment is required for the incentive. If None, all incentives are shown regardless of DR enrollment requirement.
   */
  requires_dr_enrollment?: boolean | null;
  /**
   * Requires Tou Rate
   * Whether a TOU rate enrollment is required for the incentive. If None, all incentives are shown regardless of TOU rate requirement.
   */
  requires_tou_rate?: boolean | null;
  /**
   * Reservation Required
   * Whether a reservation is required for the incentive. If None, all incentives are shown regardless of reservation requirement.
   */
  reservation_required?: boolean | null;
  /**
   * Service Types
   * The service types to filter by. Defaults to all service types.
   */
  service_types?: ServiceTypeEnum[];
  /** The new tank capacity range to filter by. If None, all incentives are shown regardless of new tank capacity are shown. Applicable to HPWH incentives only. */
  tank_capacity?: NumericRange | null;
  /**
   * Tou Programming Required
   * Whether TOU programming is required for the incentive. If None, all incentives are shown regardless of TOU programming requirement.
   */
  tou_programming_required?: boolean | null;
  /**
   * Whole Home Upgrade Required
   * Whether a whole home upgrade is required for the incentive. If None, all incentives are shown regardless of whole home upgrade requirement.
   */
  whole_home_upgrade_required?: boolean | null;
}

/** IncentiveSearchQueryFilters */
export interface IncentiveSearchQueryFilters {
  /** Filters */
  filters: RequestFilter[];
}

/** IncentiveStatusHistory */
export interface IncentiveStatusHistory {
  /**
   * Effective Date
   * @format date-time
   */
  effective_date: string;
  /** Notes */
  notes?: string | null;
  status: IncentiveProgramStatusEnum;
}

/** IncentiveTypeEnum */
export enum IncentiveTypeEnum {
  Rebate = "rebate",
  Loan = "loan",
  TaxCredit = "tax_credit",
  DirectInstall = "direct_install",
  Discount = "discount",
  PerformanceBased = "performance_based",
}

/** InitiateAuthResponse */
export interface InitiateAuthResponse {
  /** Msg */
  msg: string;
  /** Redirect Url */
  redirect_url: string | null;
}

/**
 * InputValidationError
 * FastAPI gives one of these for every bit of input that failed Pydantic parsing
 */
export interface InputValidationError {
  /** Loc */
  loc: string[];
  /** Msg */
  msg: string;
  /** Type */
  type: string;
}

/** InputValidationErrorResponse */
export interface InputValidationErrorResponse {
  /** Errors */
  errors?: InputValidationError[];
  id?: NamedErrorEnum | null;
  /** Locize Key */
  locize_key?: string | null;
  /** Message */
  message?: string | null;
}

/** InvalidateAnswerRequest */
export interface InvalidateAnswerRequest {
  /** Items */
  items: InvalidateAnswerRequestItem[];
  /** Phase Ids */
  phase_ids: string[];
  /** Phase Note */
  phase_note?: string | null;
  /** Project Id */
  project_id: string;
}

/** InvalidateAnswerRequestItem */
export interface InvalidateAnswerRequestItem {
  /** Answer Invalidation Reason */
  answer_invalidation_reason: string;
  /** Doctype Id */
  doctype_id: string;
}

/** ItemCount */
export interface ItemCount {
  /** Num Completed */
  num_completed: number;
  /** Num Remaining */
  num_remaining: number;
  /** Num Required Remaining */
  num_required_remaining: number;
}

/**
 * JobTimelineEnum
 * Represents the timeline of a potential job.
 */
export enum JobTimelineEnum {
  Asap = "asap",
  FewWeeks = "few_weeks",
  Unsure = "unsure",
}

/** JobTypeEnum */
export enum JobTypeEnum {
  HeatPumpWaterHeater = "heat_pump_water_heater",
  HeatPumpHvac = "heat_pump_hvac",
  ElectricalPanelUpgrade = "electrical_panel_upgrade",
  CasTestout = "cas_testout",
  EnergyEfficiency = "energy_efficiency",
  HeatPumpDryer = "heat_pump_dryer",
  InductionCooktop = "induction_cooktop",
  DuctSealing = "duct_sealing",
  Insulation = "insulation",
  ElectricDryer = "electric_dryer",
  Windows = "windows",
  Doors = "doors",
  EnergyAudit = "energy_audit",
  Lighting = "lighting",
  Refrigerator = "refrigerator",
  PoolPump = "pool_pump",
  ElectricWasher = "electric_washer",
  SolarElectricPanels = "solar_electric_panels",
  FuelCells = "fuel_cells",
  WindTurbines = "wind_turbines",
  BatteryStorageTechnology = "battery_storage_technology",
  GeothermalHeatPumps = "geothermal_heat_pumps",
  SolarWaterHeaters = "solar_water_heaters",
  Prewiring = "prewiring",
  AirToWaterHPAC = "air_to_water_HPAC",
}

/** ListIncentivePortalsResult */
export interface ListIncentivePortalsResult {
  /** Portals */
  portals: string[];
}

/** ListIncentivesResult */
export interface ListIncentivesResult {
  /**
   * Incentives
   * The list of incentives.
   */
  incentives?: IncentiveDetails[];
  /**
   * Related
   * The list of related incentives (e.g. addons).
   */
  related?: IncentiveDetails[];
  /**
   * Total
   * The total number of incentives satisfying the query (ignoring `limit`).
   */
  total: number;
}

/** ListIncentivesSelectionResult */
export interface ListIncentivesSelectionResult {
  /** Exclusion Map */
  exclusion_map?: Record<string, string[]>;
  /**
   * Incentives
   * The list of incentives.
   */
  incentives?: IncentiveDetails[];
  /**
   * Related
   * The list of related incentives (e.g. addons).
   */
  related?: IncentiveDetails[];
  /** Selected */
  selected?: string[];
  /**
   * Total
   * The total number of incentives satisfying the query (ignoring `limit`).
   */
  total: number;
}

/** ListUsersResult */
export interface ListUsersResult {
  /** Total */
  total: number;
  /** Users */
  users: User[];
}

/** Locale */
export interface Locale {
  enum: LocaleEnum;
  /** Label */
  label: string;
}

/** LocaleEnum */
export enum LocaleEnum {
  Em = "em",
  En = "en",
  Es = "es",
}

/**
 * LowIncomeProgramEnum
 * The type of Low Income Program for an incentive
 */
export enum LowIncomeProgramEnum {
  LowIncomeHousing = "low_income_housing",
  IndianAffairsGeneralAssistance = "indian_affairs_general_assistance",
  CaUnemploymentInsurance = "ca_unemployment_insurance",
  PandemicEmergencyUnemploymentCompensation = "pandemic_emergency_unemployment_compensation",
  PandemicUnemploymentAssistance = "pandemic_unemployment_assistance",
  CalfreshFoodStamps = "calfresh_food_stamps",
  Wic = "wic",
  CalworksTanf = "calworks_tanf",
  SupplimentalSecurityIncome = "supplimental_security_income",
  NationalSchoolLunchProgram = "national_school_lunch_program",
  LowIncomeHomeEnergyAssistanceProgram = "low_income_home_energy_assistance_program",
  EnergySavingsAssistanceProgram = "energy_savings_assistance_program",
  SolarOnMultifamilyAffordableHousing = "solar_on_multifamily_affordable_housing",
  DisadventagedCommunitiesSingleFamilySolarHomes = "disadventaged_communities_single_family_solar_homes",
  SelfGenerationIncentiveProgram = "self_generation_incentive_program",
  SingleFamilyAffordableSolarHomes = "single_family_affordable_solar_homes",
  WeatherizationAssistanceProgram = "weatherization_assistance_program",
}

/** Msg */
export interface Msg {
  /** Msg */
  msg: string;
}

/**
 * NamedErrorEnum
 * A subset of errors, but these are the named error IDs that the FE can switch
 *
 * We "namespace" using SERVICE_NAME__ERROR_TYPE
 */
export enum NamedErrorEnum {
  QUOTE_REQUEST__INVALID_CONTRACTOR = "QUOTE_REQUEST__INVALID_CONTRACTOR",
}

/** NumericRange */
export interface NumericRange {
  /** Max */
  max: number;
  /** Min */
  min: number;
}

/** OrganizationTypeEnum */
export enum OrganizationTypeEnum {
  InvestorOwnedUtility = "investor_owned_utility",
  PubliclyOwnedUtility = "publicly_owned_utility",
  CooperativeUtility = "cooperative_utility",
  RegionalEnergyNetwork = "regional_energy_network",
  CommunityEnergyAgency = "community_energy_agency",
  Government = "government",
  StateGovernment = "state_government",
  LocalGovernment = "local_government",
  StateInitiative = "state_initiative",
  NonProfit = "non_profit",
  Contractor = "contractor",
}

/** PaginatedIncentiveApplicationResponse */
export interface PaginatedIncentiveApplicationResponse {
  /** Paginated Applications */
  paginated_applications: IncentiveApplication[];
  /** Total */
  total: number;
}

/** PaymentPortalSessionResponse */
export interface PaymentPortalSessionResponse {
  /** Url */
  url: string;
}

/**
 * PaymentTypeEnum
 * The type of payment for an incentive
 */
export enum PaymentTypeEnum {
  Check = "check",
  Ach = "ach",
  BillCredit = "bill_credit",
  Wire = "wire",
  Coupon = "coupon",
  Loan = "loan",
  TaxCredit = "tax_credit",
}

/** Project */
export interface Project {
  /** Address */
  address: string | null;
  /** Amounts */
  amounts: Record<string, BundleAmount | null>;
  /**
   * Applications
   * @deprecated
   */
  applications: IncentiveApplication[] | null;
  /** Associated Businesses */
  associated_businesses: Business[] | null;
  /** Bundle Id */
  bundle_id: string;
  /** Created */
  created?: string | null;
  /**
   * Detailed Amounts
   * Map from incentive ID to list of detailed amounts
   */
  detailed_amounts: Record<string, IncentiveComputedAmount[]>;
  equipment?: Equipment | null;
  /** Has Pending Contractor Request */
  has_pending_contractor_request: boolean;
  /**
   * Ho Co Splits
   * Incentive splits between homeowner and contractor
   */
  ho_co_splits: HoCoSplit[];
  /** Id */
  id: string;
  /** Jobs */
  jobs: JobTypeEnum[];
  /** Name */
  name: string | null;
  /** Num Completed Checklist Items */
  num_completed_checklist_items?: number | null;
  /** Num Pending Checklist Items */
  num_pending_checklist_items?: number | null;
  /** Num Required Pending Checklist Items */
  num_required_pending_checklist_items?: number | null;
  performed_by: Business | null;
  /** Enum for project statuses. */
  status: ProjectStatusEnum;
  /** Thumbnail Url */
  thumbnail_url: string | null;
  /** Total Cost */
  total_cost?: number | null;
  /** Updated */
  updated?: string | null;
}

/**
 * ProjectAnswer
 * Represents a raw project answer
 */
export interface ProjectAnswer {
  /** Answer Created At */
  answer_created_at: string | null;
  /** Answer Id */
  answer_id: string | null;
  /** Answer Invalidated At */
  answer_invalidated_at: string | null;
  answer_invalidated_by: User | null;
  /** Answer Invalidation Reason */
  answer_invalidation_reason: string | null;
  answer_submitted_by: User | null;
  /** Attachments */
  attachments: ProjectChecklistItemAttachment[];
  /** Checklist Item Id */
  checklist_item_id: string;
  /** Doctype Example Urls */
  doctype_example_urls: string[];
  /** Doctype Id */
  doctype_id: string;
  /** Doctype Name */
  doctype_name: string;
  /** Doctype Reference Urls */
  doctype_reference_urls: string[];
  /** Doctype Requires Geotag */
  doctype_requires_geotag: boolean;
  /** Doctype Stage */
  doctype_stage: string;
  doctype_type: IncentiveDocTypeEnum;
  /** Is Answered */
  is_answered: boolean;
  requirement_phase: ApplicationPhaseEnum | null;
  /**
   * Requirement Portals
   * @uniqueItems true
   */
  requirement_portals: string[];
  /**
   * Requirement Programs
   * @uniqueItems true
   */
  requirement_programs: string[];
  /** Requirement Required */
  requirement_required: boolean;
  /** User Answer */
  user_answer: null;
}

/**
 * ProjectAnswersResponse
 * Represents a project answers response
 */
export interface ProjectAnswersResponse {
  /** Answers */
  answers: ProjectAnswer[];
}

/** ProjectApplicationReadyResponse */
export interface ProjectApplicationReadyResponse {
  /** Application Phases */
  application_phases: ApplicationPhaseReady[];
}

/**
 * ProjectBase
 * Base schema for a project.
 */
export interface ProjectBase {
  /** Address */
  address: string | null;
  /** Created */
  created?: string | null;
  /** Id */
  id: string;
  /** Jobs */
  jobs: JobTypeEnum[];
  /** Name */
  name: string | null;
  /** Num Completed Checklist Items */
  num_completed_checklist_items?: number | null;
  /** Num Pending Checklist Items */
  num_pending_checklist_items?: number | null;
  /** Num Required Pending Checklist Items */
  num_required_pending_checklist_items?: number | null;
  /** Enum for project statuses. */
  status: ProjectStatusEnum;
  /** Thumbnail Url */
  thumbnail_url: string | null;
  /** Total Cost */
  total_cost?: number | null;
  /** Updated */
  updated?: string | null;
}

/**
 * ProjectCard
 * Schema for a project UI card within project list page.
 */
export interface ProjectCard {
  /** Address */
  address: string | null;
  /** Created */
  created?: string | null;
  /** Id */
  id: string;
  /** Jobs */
  jobs: JobTypeEnum[];
  /** Name */
  name: string | null;
  /** Num Completed Checklist Items */
  num_completed_checklist_items?: number | null;
  /** Num Pending Checklist Items */
  num_pending_checklist_items?: number | null;
  /** Num Required Pending Checklist Items */
  num_required_pending_checklist_items?: number | null;
  /** Enum for project statuses. */
  status: ProjectStatusEnum;
  /** Thumbnail Url */
  thumbnail_url: string | null;
  /** Total Cost */
  total_cost?: number | null;
  /** Updated */
  updated?: string | null;
}

/**
 * ProjectChecklist
 * Schema for a project checklist.
 */
export interface ProjectChecklist {
  /** Item Count By Stage */
  item_count_by_stage: Record<string, ItemCount>;
  /** Items */
  items: ProjectChecklistItem[];
  /**
   * Num Completed
   * @deprecated
   */
  num_completed: number;
  /**
   * Num Pending
   * @deprecated
   */
  num_pending: number;
  /**
   * Num Required Pending
   * @deprecated
   */
  num_required_pending: number;
  /** Stages */
  stages: string[];
}

/**
 * ProjectChecklistFormField
 * Schema for a project checklist form field.
 */
export interface ProjectChecklistFormField {
  /** Data */
  data: object | null;
  /** Id */
  id: string;
  /** Invalidated At */
  invalidated_at: string | null;
  /** Invalidation Reason */
  invalidation_reason: string | null;
  /** Name */
  name: string;
  /** Required */
  required: boolean;
  type: ChecklistItemFormFieldTypeEnum;
}

/**
 * ProjectChecklistFormFieldAnswer
 * Schema for a checklist form field answer.
 */
export interface ProjectChecklistFormFieldAnswer {
  /** Id */
  id: string;
  /** Value */
  value: object;
}

/**
 * ProjectChecklistItem
 * Schema for a project checklist item.
 */
export interface ProjectChecklistItem {
  /** Completed By */
  completed_by?: string | null;
  /** Completed On */
  completed_on?: string | null;
  /** Due Date */
  due_date?: string | null;
  /** Id */
  id: string;
  /**
   * Is Editable
   * @default true
   */
  is_editable?: boolean;
  /** Is Invalidated */
  is_invalidated: boolean;
  /**
   * Is Required
   * @default false
   */
  is_required?: boolean;
  /** Name */
  name: string;
  /** Requested By */
  requested_by: string[];
  /**
   * Requires Geotag
   * @default false
   */
  requires_geotag?: boolean | null;
  /** Stage */
  stage: string;
  status: ChecklistItemStatusEnum;
  type: ChecklistItemTypeEnum;
}

/**
 * ProjectChecklistItemAttachment
 * Schema for a project checklist item attachment.
 */
export interface ProjectChecklistItemAttachment {
  /** Content Type */
  content_type?: string | null;
  /** Id */
  id: string;
  /**
   * Is Redacted
   * @default false
   */
  is_redacted?: boolean;
  /** Name */
  name?: string | null;
  /** Thumbnail Url */
  thumbnail_url?: string | null;
  /** Url */
  url?: string | null;
}

/** ProjectChecklistItemDetails */
export interface ProjectChecklistItemDetails {
  /** Attachments */
  attachments?: ProjectChecklistItemAttachment[] | null;
  /** Attachments Invalidated At */
  attachments_invalidated_at: string | null;
  /** Attachments Invalidation Reason */
  attachments_invalidation_reason: string | null;
  /** Completed By */
  completed_by?: string | null;
  /** Completed On */
  completed_on?: string | null;
  /** Due Date */
  due_date?: string | null;
  /** Event History */
  event_history: ProjectChecklistItemEvent[];
  /** Example Images */
  example_images?: ProjectChecklistItemAttachment[] | null;
  /** Form Field Groupings */
  form_field_groupings: ChecklistItemFormFieldGrouping[];
  /** Form Fields */
  form_fields?: ProjectChecklistFormField[] | null;
  /** Id */
  id: string;
  /**
   * Is Editable
   * @default true
   */
  is_editable?: boolean;
  /**
   * Is Required
   * @default false
   */
  is_required?: boolean;
  /** Name */
  name: string;
  /** Reference Docs */
  reference_docs?: ProjectChecklistItemAttachment[] | null;
  /** Requested By */
  requested_by: string[];
  /**
   * Requires Geotag
   * @default false
   */
  requires_geotag?: boolean | null;
  /** Stage */
  stage: string;
  status: ChecklistItemStatusEnum;
  type: ChecklistItemTypeEnum;
}

/** ProjectChecklistItemEvent */
export interface ProjectChecklistItemEvent {
  /** Email */
  email: string | null;
  /**
   * Event Time
   * @format date-time
   */
  event_time: string;
  event_type: ChecklistItemEventEnum;
}

/**
 * ProjectChecklistItemSubmitRequest
 * Schema for a project checklist item submission request.
 */
export interface ProjectChecklistItemSubmitRequest {
  /**
   * Attachments
   * @default []
   */
  attachments?: ProjectChecklistItemAttachment[];
  /**
   * Form Responses
   * @default []
   */
  form_responses?: ProjectChecklistFormFieldAnswer[];
  /** Id */
  id: string;
}

/**
 * ProjectChecklistItemUploadResponse
 * Schema for a project checklist item file upload response.
 */
export interface ProjectChecklistItemUploadResponse {
  /** Schema for a project checklist item attachment. */
  attachment: ProjectChecklistItemAttachment;
  /** Form Fields */
  form_fields?: ProjectChecklistFormField[] | null;
}

/** ProjectCollaborator */
export interface ProjectCollaborator {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  role: ProjectCollaboratorRoleEnum;
  /** User Id */
  user_id: string;
}

/** ProjectCollaboratorRoleEnum */
export enum ProjectCollaboratorRoleEnum {
  Owner = "owner",
  Editor = "editor",
  Viewer = "viewer",
  Ops = "ops",
}

/** ProjectContractorRequest */
export interface ProjectContractorRequest {
  /** Notes */
  notes?: string | null;
}

/**
 * ProjectCreateRequest
 * Schema for a project create request.
 */
export interface ProjectCreateRequest {
  /** Bundle Id */
  bundle_id: string;
  /** Name */
  name: string;
  /**
   * Team Ids
   * IDs of teams to add to the project
   */
  team_ids?: string[];
}

/** ProjectInvitation */
export interface ProjectInvitation {
  inviting_user: TeamUser;
  /** Base schema for a project. */
  project: ProjectBase;
  /** Token */
  token: string;
}

/**
 * ProjectStatsDataPoint
 * A data point for project statistics.
 */
export interface ProjectStatsDataPoint {
  /** Created */
  created: number;
  /** End */
  end: string | null;
  /**
   * Start
   * @format date-time
   */
  start: string;
  /** Submitted */
  submitted: number;
}

/**
 * ProjectStatusEnum
 * Enum for project statuses.
 */
export enum ProjectStatusEnum {
  InProgress = "in_progress",
  NeedsAttention = "needs_attention",
  Submitted = "submitted",
  Approved = "approved",
  Archived = "archived",
}

/** ProjectTypeEnum */
export enum ProjectTypeEnum {
  NewConstruction = "new_construction",
  RemodelRetrofit = "remodel_retrofit",
  ServiceMaintenance = "service_maintenance",
}

/**
 * ProjectUpdateRequest
 * Schema for a project update request.
 */
export interface ProjectUpdateRequest {
  /** Equipment Id */
  equipment_id?: string | null;
  /** Ho Co Splits */
  ho_co_splits?: HoCoSplit[] | null;
  /** Name */
  name?: string | null;
  /** Total Cost */
  total_cost?: number | null;
}

/** PropertyTypeEnum */
export enum PropertyTypeEnum {
  SingleFamily = "single_family",
  Adu = "adu",
  ManufacturedHome = "manufactured_home",
  MobileHome = "mobile_home",
  SmallMultifamily = "small_multifamily",
  MediumMultifamily = "medium_multifamily",
  SmallCommercial = "small_commercial",
  Industrial = "industrial",
}

/** QuoteRequest */
export interface QuoteRequest {
  contractor: ContractorWithUsers;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** QuoteRequestCreate */
export interface QuoteRequestCreate {
  /**
   * Contractor Org Ids
   * contractors to request quotes from
   */
  contractor_org_ids: string[];
  /**
   * Quotable Job Id
   * ID of the quotable job
   */
  quotable_job_id: string;
}

/** RequestFilter */
export interface RequestFilter {
  /** Name */
  name: string;
  /** Value */
  value: object;
}

/** ServerSnapshotVersion */
export interface ServerSnapshotVersion {
  /** Incentive Data Schema Version */
  incentive_data_schema_version: string;
  /**
   * Loaded At
   * @format date-time
   */
  loaded_at: string;
  /** Server Incentive Data Schema Version */
  server_incentive_data_schema_version: string;
  /** Snapshot Hash */
  snapshot_hash: string;
}

/** ServiceType */
export interface ServiceType {
  enum: ServiceTypeEnum;
  /** Icon Url */
  icon_url: string;
  /** Label */
  label: string;
}

/** ServiceTypeEnum */
export enum ServiceTypeEnum {
  AirSealing = "air_sealing",
  Batteries = "batteries",
  ClothesDrying = "clothes_drying",
  ClothesWasher = "clothes_washer",
  Cooking = "cooking",
  DuctWork = "duct_work",
  Efficiency = "efficiency",
  Electrical = "electrical",
  EvCharging = "ev_charging",
  Fireplace = "fireplace",
  Hvac = "hvac",
  Insulation = "insulation",
  PoolProducts = "pool_products",
  Pv = "pv",
  WaterHeating = "water_heating",
}

/** SignatureStatus */
export interface SignatureStatus {
  /** Email */
  email: string;
  /** Role */
  role: string;
  /** Signed */
  signed: boolean;
  /**
   * Updated
   * @format date-time
   */
  updated: string;
}

/** SubmissionValidationErrorResponse */
export interface SubmissionValidationErrorResponse {
  /** Errors */
  errors?: FieldValidationFailure[];
  id?: NamedErrorEnum | null;
  /** Locize Key */
  locize_key?: string | null;
  /** Message */
  message?: string | null;
}

/** SupportedLocales */
export interface SupportedLocales {
  /** Locales */
  locales: Locale[];
  /** The current user's locale */
  user_locale: LocaleEnum;
}

/**
 * SupportedUSStatesEnum
 * Enumeration of supported US states.
 */
export enum SupportedUSStatesEnum {
  Ca = "ca",
  Co = "co",
  Ny = "ny",
}

/** Team */
export interface Team {
  /**
   * Created
   * The date the team was created
   * @format date-time
   */
  created: string;
  /** The user who created the team */
  created_by_user: TeamUser;
  /**
   * Id
   * The team's ID
   */
  id: string;
  /**
   * Invites
   * The team's invites
   */
  invites: TeamInvite[];
  /**
   * Is Contractor Team
   * Is this team linked to a contractor organization
   */
  is_contractor_team: boolean;
  /**
   * Name
   * The team's name
   */
  name: string;
  /** The team's project info, if the team is project-specific */
  project: AppApiApiV1SchemasTeamsProjectMinimal | null;
  /**
   * Project Id
   * The team's project ID, if the team is project-specific
   */
  project_id: string | null;
  /**
   * Users
   * The team's members
   */
  users: TeamUser[];
}

/** TeamInvite */
export interface TeamInvite {
  /**
   * Created At
   * The date the invite was created
   * @format date-time
   */
  created_at: string;
  /**
   * Invitee Email
   * The email address of the invitee, if invited by email
   */
  invitee_email: string | null;
  /**
   * Invitee Phone Number
   * The phone number of the invitee, if invited by phone
   */
  invitee_phone_number: string | null;
  /** The user who sent the invite */
  inviter_user: TeamUser;
  /**
   * Is Valid
   * Whether the invite is still valid
   */
  is_valid: boolean;
  /** The team being invited to */
  team: TeamMinimal;
  /**
   * Token
   * The token used for verification
   */
  token: string;
}

/** TeamInviteRequest */
export interface TeamInviteRequest {
  /**
   * Email
   * The email address of the invitee
   */
  email?: string | null;
  /**
   * Phone Number
   * The phone number of the invitee
   */
  phone_number?: string | null;
}

/** TeamInviteRespondRequest */
export interface TeamInviteRespondRequest {
  /**
   * Accept
   * Whether to accept the invite
   */
  accept: boolean;
}

/**
 * TeamMinimal
 * Team view for non-members
 */
export interface TeamMinimal {
  /**
   * Id
   * The team's ID
   */
  id: string;
  /**
   * Name
   * The team's name
   */
  name: string;
  /** The team's project info, if the team is project-specific */
  project: AppApiApiV1SchemasTeamsProjectMinimal | null;
  /**
   * Project Id
   * The team's project ID, if the team is project-specific
   */
  project_id: string | null;
}

/** TeamRemoveRequest */
export interface TeamRemoveRequest {
  /**
   * User Id
   * The ID of the user to remove
   */
  user_id: string;
}

/** TeamUser */
export interface TeamUser {
  /** Email */
  email?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Id */
  id: string;
  /** Last Name */
  last_name?: string | null;
  /** Phone Number */
  phone_number?: string | null;
}

/** Token */
export interface Token {
  /** Access Token */
  access_token: string;
  /** Token Type */
  token_type: string;
  /** User Id */
  user_id?: string | null;
  user_status: AppEnumsEnumsUserStatus;
}

/**
 * UpdateApplicationReferencesRequest
 * Application external references update request
 */
export interface UpdateApplicationReferencesRequest {
  /** References */
  references: object;
}

/** UpdateContractorRequest */
export interface UpdateContractorRequest {
  /** Google Business Id */
  google_business_id?: string | null;
  /** Logo Upload Id */
  logo_upload_id?: string | null;
  /** Programs */
  programs?: string[];
  /** Services Offered */
  services_offered?: ServiceTypeEnum[];
}

/** UpdateContractorResponse */
export interface UpdateContractorResponse {
  contractor_self: ContractorSelf;
}

/**
 * UpdatePhaseStatusRequest
 * Update phase status.
 */
export interface UpdatePhaseStatusRequest {
  /** Application Id */
  application_id: string;
  /**
   * The status of an application phase for a particular project
   *
   * https://rock-rabbit.atlassian.net/wiki/spaces/RockRabbit/pages/150077441/Application+Submission#Statuses
   */
  new_status: ApplicationPhaseStatusEnum;
  /**
   * Enumeration of application phases. Each incentive application may have
   * 1 or more phases, each of which may have a different (likely overlapping)
   * set of requirements.
   */
  phase_name: ApplicationPhaseEnum;
  /** Phase Note */
  phase_note?: string | null;
  /** Project Id */
  project_id: string;
}

/** UpdateTeamRequest */
export interface UpdateTeamRequest {
  /**
   * Name
   * The team's name
   */
  name: string;
}

/** UpdateUserRequest */
export interface UpdateUserRequest {
  /**
   * Communications Accepted
   * Consent to receive marketing emails
   */
  communications_accepted?: boolean | null;
  /**
   * Email
   * Email
   */
  email?: string | null;
  /**
   * First Name
   * First name
   */
  first_name?: string | null;
  /**
   * Last Name
   * Last name
   */
  last_name?: string | null;
  /** Preferred locale */
  locale?: LocaleEnum | null;
  /**
   * Password
   * Password
   */
  password?: string | null;
  /**
   * Phone Number
   * Phone number
   */
  phone_number?: string | null;
  /**
   * Profile Photo Id
   * Profile photo upload ID
   */
  profile_photo_id?: string | null;
  /** User type */
  user_type?: UserType | null;
}

/** UpdateUserTypeRequest */
export interface UpdateUserTypeRequest {
  /**
   * License Number
   * License number (deprecated, use new_organization.license_number instead)
   * @deprecated
   */
  license_number?: string | null;
  /** New organization */
  new_organization?: ContractorRegistryEntryRequest | null;
  /**
   * State granting the license (deprecated, use new_organization.state instead)
   * @deprecated
   */
  state?: SupportedUSStatesEnum | null;
  /** User type */
  user_type: UserType;
}

/**
 * Upload
 * A user-uploaded file we're managing on the server
 */
export interface Upload {
  /**
   * Content Type
   * Content type of the file
   */
  content_type: string;
  /**
   * Id
   * Upload ID
   */
  id: string;
  /**
   * Public Url
   * URL where the file is available
   */
  public_url: string | null;
  /**
   * Upload Url
   * URL where the file can be uploaded
   */
  upload_url: string | null;
}

/** User */
export interface User {
  /**
   * Communications Accepted
   * Consented to receive marketing emails?
   */
  communications_accepted: boolean;
  /** Contractor Orgs */
  contractor_orgs: Contractor[];
  /** Email */
  email?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Id */
  id: string;
  /**
   * Is Email Verified
   * Is email verified?
   * @default false
   */
  is_email_verified?: boolean;
  /**
   * Is Phone Number Verified
   * Is phone number verified?
   * @default false
   */
  is_phone_number_verified?: boolean;
  /** Last Name */
  last_name?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Profile photo */
  profile_photo?: Upload | null;
  /**
   * Signed Tos
   * Has signed the terms of service?
   */
  signed_tos: boolean;
  /** User status */
  status: AppApiApiV1SchemasUserUserStatus;
  /** User type */
  user_type: UserType;
  /**
   * Verified Svce
   * Verified SVCE user
   * @default false
   */
  verified_svce?: boolean;
}

/** UserStatus */
export enum UserStatusInput {
  PendingSignup = "pending_signup",
  Active = "active",
  PendingUserTypeSelection = "pending_user_type_selection",
  PendingContractorOrgSelection = "pending_contractor_org_selection",
  PendingContractorOrgApproval = "pending_contractor_org_approval",
  Suspended = "suspended",
}

/** UserType */
export enum UserType {
  Contractor = "contractor",
  Homeowner = "homeowner",
  OemDistributor = "oem_distributor",
  IncentiveProvider = "incentive_provider",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** VerifyOtpBody */
export interface VerifyOtpBody {
  /** Email */
  email?: string | null;
  locale?: LocaleEnum | null;
  /** Otp */
  otp: string;
  /** Phone Number */
  phone_number?: string | null;
}

/** WaterHeaterTypeEnum */
export enum WaterHeaterTypeEnum {
  StorageTank = "storage_tank",
  Tankless = "tankless",
}

/** WizardAbortRequest */
export interface WizardAbortRequest {
  /** Reason */
  reason?: string | null;
  /** Session Id */
  session_id: string;
}

/** WizardAbortResponse */
export interface WizardAbortResponse {
  /** Session Id */
  session_id: string;
  session_status: WizardSessionStatusEnum;
}

/** WizardAnswer */
export interface WizardAnswer {
  /** Question Id */
  question_id: string;
  /** Value */
  value: any;
}

/** WizardBacktrackRequest */
export interface WizardBacktrackRequest {
  /** Session Id */
  session_id: string;
}

/** WizardBacktrackResponse */
export interface WizardBacktrackResponse {
  /** Session Id */
  session_id: string;
}

/** WizardCompleteRequest */
export interface WizardCompleteRequest {
  /** Session Id */
  session_id: string;
}

/** WizardCompleteResponse */
export interface WizardCompleteResponse {
  /**
   * Bundle Id
   * The ID of the created bundle, if the wizard resulted in a bundle.
   */
  bundle_id?: string | null;
  /**
   * Quotable Job Id
   * The ID of the created quotable job, if the wizard resulted in a quotable job.
   */
  quotable_job_id?: string | null;
  /** Session Id */
  session_id: string;
}

/** WizardCurrentResponse */
export interface WizardCurrentResponse {
  /** Question Path */
  question_path: string[] | null;
  /** Questions */
  questions: WizardQuestion[];
  /** Session Id */
  session_id: string;
  session_status: WizardSessionStatusEnum;
}

/** WizardNextRequest */
export interface WizardNextRequest {
  /** Answers */
  answers?: WizardAnswer[] | null;
  /** Session Id */
  session_id: string;
}

/** WizardNextResponse */
export interface WizardNextResponse {
  /** Question Path */
  question_path: string[] | null;
  /** Questions */
  questions: WizardQuestion[];
  /** Session Id */
  session_id: string;
  session_status: WizardSessionStatusEnum;
}

/** WizardQuestion */
export interface WizardQuestion {
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /**
   * Is Final
   * @default false
   */
  is_final?: boolean;
  /**
   * Is Required
   * @default false
   */
  is_required?: boolean;
  /** Options */
  options?:
    | WizardQuestionOptionsText
    | WizardQuestionOptionsNumber
    | WizardQuestionOptionsLocation
    | WizardQuestionOptionsChoices
    | WizardQuestionOptionsAgreement
    | WizardQuestionOptionsPayment
    | WizardQuestionOptionsInfo
    | null;
  /** Question */
  question?: string | null;
  /** Different types of questions we display to the user in the wizard */
  type: WizardQuestionTypeEnum;
}

/**
 * WizardQuestionOptionsAgreement
 * Options for an agreement-type question.
 */
export interface WizardQuestionOptionsAgreement {
  /** Name */
  name: string;
  /** Url */
  url: string;
}

/** WizardQuestionOptionsChoices */
export interface WizardQuestionOptionsChoices {
  /** Type */
  type: "named_list";
  /** Values */
  values: WizardQuestionOptionsChoicesValue[];
}

/** WizardQuestionOptionsChoicesValue */
export interface WizardQuestionOptionsChoicesValue {
  /** Disabled */
  disabled: boolean;
  /** Is Default */
  is_default: boolean;
  /** Key */
  key: string;
  /** Name */
  name: string;
}

/**
 * WizardQuestionOptionsInfo
 * Options for an info-type question.
 */
export interface WizardQuestionOptionsInfo {
  /** Post Icon Url */
  post_icon_url?: string | null;
}

/** WizardQuestionOptionsLocation */
export interface WizardQuestionOptionsLocation {
  /**
   * Type
   * @default "location"
   */
  type?: "location";
}

/** WizardQuestionOptionsNumber */
export interface WizardQuestionOptionsNumber {
  /** Type */
  type: "numeric_int" | "numeric_float";
  values: WizardQuestionOptionsNumberValues;
}

/** WizardQuestionOptionsNumberValues */
export interface WizardQuestionOptionsNumberValues {
  /** Default */
  default: number;
  /** Increment */
  increment: number;
  /** Max */
  max: number;
  /** Min */
  min: number;
}

/**
 * WizardQuestionOptionsPayment
 * Options for a payment-type question.
 */
export interface WizardQuestionOptionsPayment {
  /** Contractor Id */
  contractor_id: string;
}

/** WizardQuestionOptionsText */
export interface WizardQuestionOptionsText {
  /** Default */
  default?: string | null;
  /** Length */
  length?: number | null;
  /** Str Type */
  str_type?: "email" | "phone" | null;
}

/**
 * WizardQuestionTypeEnum
 * Different types of questions we display to the user in the wizard
 */
export enum WizardQuestionTypeEnum {
  SingleChoice = "single_choice",
  MultiChoice = "multi_choice",
  Location = "location",
  Numeric = "numeric",
  Text = "text",
  Agreement = "agreement",
  Payment = "payment",
  Info = "info",
}

/** WizardSessionStatusEnum */
export enum WizardSessionStatusEnum {
  InProgress = "in_progress",
  Completed = "completed",
  Aborted = "aborted",
  Expired = "expired",
}

/** WizardStartRequest */
export interface WizardStartRequest {
  /** Initial data for the wizard session. */
  initial_data?: WizardStartRequestInitialData;
}

/**
 * WizardStartRequestInitialData
 * Initial data for the wizard session.
 */
export interface WizardStartRequestInitialData {
  /**
   * Aos Brand
   * The brand of the wizard flow, if applicable.
   */
  aos_brand?: string | null;
  /**
   * Contractor Ids
   * Ids of any contractors we want to get quotes from.
   */
  contractor_ids?: string[] | null;
  /**
   * Lead Id
   * The lead id from ad referrals, for tracking.
   */
  lead_id?: string | null;
}

/** WizardStartResponse */
export interface WizardStartResponse {
  /** Question Path */
  question_path: string[] | null;
  /** Questions */
  questions: WizardQuestion[];
  /** Session Id */
  session_id: string;
  session_status: WizardSessionStatusEnum;
}

export enum MessageSeverityEnum {
  Success = "success",
  Info = "info",
  Warn = "warn",
  Error = "error",
}

export type BaseMessage = {
  severity: MessageSeverityEnum;
  summary?: string;
  detail?: string;
  list?: string[];
};

export type FormMessage = {
  severity: MessageSeverityEnum;
  field: string;
  detail: string;
};

export type DefaultError = {
  status_code: number;
  error_id: NamedErrorEnum | null;
  baseMessages: BaseMessage[];
  formMessages: FormMessage[];
};

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

type APIErrors = ErrorResponse | InputValidationErrorResponse | SubmissionValidationErrorResponse;

export const isErrorResponse = (obj: APIErrors): obj is ErrorResponse => {
  return (
    ("id" in obj && (typeof obj.message === "string" || obj.message === null)) ||
    ("message" in obj && typeof obj.message === "string") ||
    ("locize_key" in obj && typeof obj.locize_key === "string")
  );
};

export const isValidationError = (obj: APIErrors): obj is InputValidationErrorResponse => {
  return (
    "errors" in obj &&
    Array.isArray(obj.errors) &&
    obj.errors.every((err) => typeof err === "object" && "loc" in err && typeof err.loc === "object")
  );
};

export const isApplicationSubmitError = (obj: APIErrors): obj is SubmissionValidationErrorResponse => {
  return (
    "errors" in obj &&
    Array.isArray(obj.errors) &&
    obj.errors.every((err) => typeof err === "object" && !("loc" in err))
  );
};

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);
  private readonly DEFAULT_ERROR_LOCIZE_KEY = "common.default_error_message";

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public parse = async <T,>(promise: Promise<HttpResponse<T>>): Promise<T> => {
    return promise
      .then((response) => response.data)
      .catch((response) => {
        try {
          if (isApplicationSubmitError(response.error)) {
            const summary = response.error.message;
            const list = (response.error.errors as FieldValidationFailure[])
              .filter((error) => !!error.severity && !!error.message)
              .map((error) => error.message!);

            return Promise.reject({
              status_code: response.status,
              error_id: null,
              baseMessages: [
                {
                  severity: MessageSeverityEnum.Error,
                  summary,
                  list,
                },
              ],
              formMessages: [],
            });
          }

          if (isValidationError(response.error)) {
            return Promise.reject({
              status_code: response.status,
              error_id: null,
              baseMessages: response.error.message
                ? [
                    {
                      severity: MessageSeverityEnum.Error,
                      detail: response.error.message,
                    },
                  ]
                : [],
              formMessages: ((response.error.errors ?? []) as InputValidationError[]).reduce<FormMessage[]>(
                (messages, error) => {
                  const [first, ...fieldPath] = error.loc;

                  /**
                   * TODO:
                   *
                   * Maybe in the future, we need to handle other error locations
                   * For now, we only handle the error location in the body
                   */
                  if (first === "body") {
                    return messages.concat({
                      severity: MessageSeverityEnum.Error,
                      field: fieldPath.join("."),
                      detail: error.msg,
                    });
                  }

                  return messages;
                },
                [],
              ),
            });
          }

          if (isErrorResponse(response.error)) {
            return Promise.reject({
              status_code: response.status,
              error_id: response.error.id,
              baseMessages: [
                {
                  severity: MessageSeverityEnum.Error,
                  // this must be string because if it's not, it will be caught by isErrorResponse
                  detail: response.error.message!,
                },
              ],
              formMessages: [],
            });
          }
        } catch (_) {}

        return Promise.reject({
          status_code: response.status,
          error_id: null,
          baseMessages: [
            {
              severity: MessageSeverityEnum.Error,
              detail: this.DEFAULT_ERROR_LOCIZE_KEY,
            },
          ],
          formMessages: [],
        });
      });
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    let secureParams = {};
    if (typeof secure === "boolean" ? secure : this.baseApiParams.secure) {
      if (this.securityWorker) {
        const maybeParams = await this.securityWorker(this.securityData);
        secureParams = maybeParams || {};
      }
    }

    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    const url = `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`;
    const response = await this.customFetch(url, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    });

    const r = response as HttpResponse<T, E>;
    r.data = null as unknown as T;
    r.error = null as unknown as E;

    const data = r;
    if (responseFormat) {
      try {
        const parsed = await response[responseFormat]();
        if (r.ok) {
          r.data = parsed;
        } else {
          r.error = parsed;
        }
      } catch (e) {
        data.error = e as E;
      }
    }

    if (cancelToken) {
      this.abortControllers.delete(cancelToken);
    }

    if (!response.ok) throw data;
    return data;
  };
}

/**
 * @title Rock Rabbit API
 * @version demo0.4.78
 */
export class BackendAPI<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsGetCurrentProjectAmounts
     * @summary Get Current Project Amounts
     * @request GET:/api/v1/analytics/project-amounts-by-status
     * @secure
     */
    analyticsGetCurrentProjectAmounts: (params: RequestParams = {}) =>
      this.parse(
        this.request<Record<string, number>, ErrorResponse>({
          path: `/api/v1/analytics/project-amounts-by-status`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsGetCurrentProjectCounts
     * @summary Get Current Project Counts
     * @request GET:/api/v1/analytics/project-counts-by-status
     * @secure
     */
    analyticsGetCurrentProjectCounts: (params: RequestParams = {}) =>
      this.parse(
        this.request<Record<string, number>, ErrorResponse>({
          path: `/api/v1/analytics/project-counts-by-status`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsGetIncentiveAlerts
     * @summary Get Incentive Alerts
     * @request GET:/api/v1/analytics/incentive-change-alerts
     * @secure
     */
    analyticsGetIncentiveAlerts: (
      query?: {
        /** Location Filter */
        location_filter?: string[] | null;
        /** Type Filter */
        type_filter?: string[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<IncentiveChangeAlert[], ErrorResponse>({
          path: `/api/v1/analytics/incentive-change-alerts`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsGetProjectAmounts
     * @summary Get Project Amounts
     * @request GET:/api/v1/analytics/project-amounts-over-time
     * @secure
     */
    analyticsGetProjectAmounts: (
      query?: {
        /**
         * End
         * End date for the time range
         */
        end?: string | null;
        /**
         * Start
         * Start date for the time range
         */
        start?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<AggregationResponseProjectStatsDataPoint, ErrorResponse>({
          path: `/api/v1/analytics/project-amounts-over-time`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags analytics
     * @name AnalyticsGetProjectCounts
     * @summary Get Project Counts
     * @request GET:/api/v1/analytics/project-counts-over-time
     * @secure
     */
    analyticsGetProjectCounts: (
      query?: {
        /** End */
        end?: string | null;
        /** Start */
        start?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<AggregationResponseProjectStatsDataPoint, ErrorResponse>({
          path: `/api/v1/analytics/project-counts-over-time`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags applications
     * @name ApplicationsGetApplications
     * @summary Get Applications
     * @request GET:/api/v1/applications/
     * @secure
     */
    applicationsGetApplications: (
      query?: {
        /**
         * Limit
         * @default 20
         */
        limit?: number;
        /**
         * Offset
         * @default 0
         */
        offset?: number;
        /** Phase Filter */
        phase_filter?: ApplicationPhaseEnum | null;
        /** Project Name Filter */
        project_name_filter?: string | null;
        /** Status Filter */
        status_filter?: ApplicationPhaseStatusEnum[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<PaginatedIncentiveApplicationResponse, any>({
          path: `/api/v1/applications/`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Exchange Daikin authorization code for a Daikin access token. Return a RR access token that expires at the same time as the Daikin token.
     *
     * @tags login
     * @name AuthExchangeDaikinOauthCode
     * @summary Exchange Daikin Oauth Code
     * @request POST:/api/v1/auth/daikin-auth
     * @secure
     */
    authExchangeDaikinOauthCode: (
      query: {
        /** Code */
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<Token, ErrorResponse>({
          path: `/api/v1/auth/daikin-auth`,
          method: "POST",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Redirect to the Daikin authorization screen.
     *
     * @tags login
     * @name AuthInitiateDaikinLogin
     * @summary Initiate Daikin Login
     * @request GET:/api/v1/auth/daikin-login
     */
    authInitiateDaikinLogin: (params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/auth/daikin-login`,
          method: "GET",
          ...params,
        }),
      ),

    /**
     * @description Create a new bundle from a wizard session and return the first set of questions. If no incentives are selected, the wizard session will be used to select incentives.
     *
     * @tags bundles
     * @name BundlesCreateBundle
     * @summary Create Bundle
     * @request POST:/api/v1/bundles/create
     * @deprecated
     * @secure
     */
    bundlesCreateBundle: (data: IncentiveBundleCreateRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveBundle, InputValidationErrorResponse | ErrorResponse>({
          path: `/api/v1/bundles/create`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Export a bundle.
     *
     * @tags bundles
     * @name BundlesExportBundle
     * @summary Export Bundle
     * @request POST:/api/v1/bundles/{bundle_id}/export
     * @secure
     */
    bundlesExportBundle: (bundleId: string, data: BundleExportRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<any, InputValidationErrorResponse | ErrorResponse>({
          path: `/api/v1/bundles/${bundleId}/export`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the details of a bundle.
     *
     * @tags bundles
     * @name BundlesGetBundle
     * @summary Get Bundle
     * @request GET:/api/v1/bundles/{bundle_id}/details
     * @secure
     */
    bundlesGetBundle: (bundleId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveBundle, ErrorResponse>({
          path: `/api/v1/bundles/${bundleId}/details`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the details of a bundle. This is an unauthenticated endpoint.
     *
     * @tags bundles
     * @name BundlesGetBundlePreview
     * @summary Get Bundle Preview
     * @request GET:/api/v1/bundles/{bundle_id}/preview
     * @secure
     */
    bundlesGetBundlePreview: (bundleId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveBundle, ErrorResponse>({
          path: `/api/v1/bundles/${bundleId}/preview`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the eligible equipment for a bundle.
     *
     * @tags bundles
     * @name BundlesGetEligibleEquipment
     * @summary Get Eligible Equipment
     * @request GET:/api/v1/bundles/{bundle_id}/eligible-equipment
     * @secure
     */
    bundlesGetEligibleEquipment: (bundleId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Equipment[], ErrorResponse>({
          path: `/api/v1/bundles/${bundleId}/eligible-equipment`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the list of incentives for a given session
     *
     * @tags bundles
     * @name BundlesGetIncentivesForBundle
     * @summary Get Incentives For Bundle
     * @request POST:/api/v1/bundles/{bundle_id}/incentives
     * @secure
     */
    bundlesGetIncentivesForBundle: (
      bundleId: string,
      data: IncentiveBundleListIncentivesRequest,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ListIncentivesSelectionResult, ErrorResponse>({
          path: `/api/v1/bundles/${bundleId}/incentives`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Update a bundle. If  `incentive_ids` and `incentive_types` are provided, the incentives of these types will be replaced with the provided IDs. If `incentive_ids` is provided, but `incentive_type` is not, all incentives for a bundle will be replaced with provided IDs.
     *
     * @tags bundles
     * @name BundlesUpdateBundle
     * @summary Update Bundle
     * @request PUT:/api/v1/bundles/{bundle_id}
     * @secure
     */
    bundlesUpdateBundle: (bundleId: string, data: IncentiveBundleUpdateRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveBundle, ErrorResponse>({
          path: `/api/v1/bundles/${bundleId}`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Add a certification to a contractor.
     *
     * @tags contractors
     * @name ContractorsAddCertificationToContractor
     * @summary Add Certification To Contractor
     * @request PUT:/api/v1/contractors/{contractor_id}/certification/{certification_id}
     * @secure
     */
    contractorsAddCertificationToContractor: (
      contractorId: string,
      certificationId: string,
      data: BodyAddCertificationToContractorApiV1ContractorsContractorIdCertificationCertificationIdPut,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<UpdateContractorResponse, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/certification/${certificationId}`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.FormData,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Add a service area to a contractor.
     *
     * @tags contractors
     * @name ContractorsAddServiceAreaToContractor
     * @summary Add Service Area To Contractor
     * @request POST:/api/v1/contractors/{contractor_id}/service_areas/
     * @secure
     */
    contractorsAddServiceAreaToContractor: (
      contractorId: string,
      query: {
        /** Address */
        address: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<UpdateContractorResponse, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/service_areas/`,
          method: "POST",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Return all possible certifications.
     *
     * @tags contractors
     * @name ContractorsAllCertifications
     * @summary All Certifications
     * @request GET:/api/v1/contractors/all_certifications
     * @secure
     */
    contractorsAllCertifications: (params: RequestParams = {}) =>
      this.parse(
        this.request<Certification[], any>({
          path: `/api/v1/contractors/all_certifications`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Return all possible service types.
     *
     * @tags contractors
     * @name ContractorsAllServiceTypes
     * @summary All Service Types
     * @request GET:/api/v1/contractors/all_service_types
     * @secure
     */
    contractorsAllServiceTypes: (
      query?: {
        /**
         * Only Rr Supported
         * Whether to return only service types supported by Rock Rabbit.
         * @default false
         */
        only_rr_supported?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ServiceType[], any>({
          path: `/api/v1/contractors/all_service_types`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Associate a contractor user with an unclaimed contractor org.
     *
     * @tags contractors
     * @name ContractorsClaimContractor
     * @summary Claim Contractor
     * @request POST:/api/v1/contractors/claim
     * @secure
     */
    contractorsClaimContractor: (data: ContractorRegistryEntryRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Contractor, ErrorResponse>({
          path: `/api/v1/contractors/claim`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Search for contractors.
     *
     * @tags contractors
     * @name ContractorsFindContractors
     * @summary Find Contractors
     * @request GET:/api/v1/contractors/find
     * @secure
     */
    contractorsFindContractors: (
      query?: {
        /** Name */
        name?: string | null;
        /** Service Address */
        service_address?: string | null;
        /** Service Type */
        service_type?: ServiceTypeEnum | null;
        /** Svce Eligible Only */
        svce_eligible_only?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<Contractor[], ErrorResponse>({
          path: `/api/v1/contractors/find`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Retrieve a contractor by ID.
     *
     * @tags contractors
     * @name ContractorsGetContractor
     * @summary Get Contractor
     * @request GET:/api/v1/contractors/{contractor_id}
     * @secure
     */
    contractorsGetContractor: (contractorId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Contractor, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Retrieve a contractor by ID. This is used for a contractor to retrieve information about their own organization. The `ContractorSelf` object contains non-public information.
     *
     * @tags contractors
     * @name ContractorsGetContractorSelf
     * @summary Get Contractor Self
     * @request GET:/api/v1/contractors/{contractor_id}/self
     * @secure
     */
    contractorsGetContractorSelf: (contractorId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorSelf, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/self`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a team invite token, accept or reject the invitation
     *
     * @tags contractors
     * @name ContractorsJoinContractor
     * @summary Join Contractor
     * @request POST:/api/v1/contractors/join/{token}
     * @secure
     */
    contractorsJoinContractor: (token: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, ErrorResponse>({
          path: `/api/v1/contractors/join/${token}`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags contractors
     * @name ContractorsPaymentPortalSession
     * @summary Payment Portal Session
     * @request POST:/api/v1/contractors/{contractor_id}/payment_portal_session
     * @secure
     */
    contractorsPaymentPortalSession: (
      contractorId: string,
      query: {
        /** Return Url */
        return_url: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<PaymentPortalSessionResponse, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/payment_portal_session`,
          method: "POST",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Return all programs that a contractor is eligible to participate in. Eligibility is determined by geography.
     *
     * @tags contractors
     * @name ContractorsProgramOptions
     * @summary Program Options
     * @request GET:/api/v1/contractors/{contractor_id}/program_options
     * @secure
     */
    contractorsProgramOptions: (contractorId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorProgram[], ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/program_options`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Remove a certification from a contractor.
     *
     * @tags contractors
     * @name ContractorsRemoveCertificationFromContractor
     * @summary Remove Certification From Contractor
     * @request DELETE:/api/v1/contractors/{contractor_id}/certification/{certification_id}
     * @secure
     */
    contractorsRemoveCertificationFromContractor: (
      contractorId: string,
      certificationId: string,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<UpdateContractorResponse, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/certification/${certificationId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Remove a service area from a contractor.
     *
     * @tags contractors
     * @name ContractorsRemoveServiceAreaFromContractor
     * @summary Remove Service Area From Contractor
     * @request DELETE:/api/v1/contractors/{contractor_id}/service_areas/{geography_id}
     * @secure
     */
    contractorsRemoveServiceAreaFromContractor: (
      contractorId: string,
      geographyId: string,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<UpdateContractorResponse, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}/service_areas/${geographyId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Partially update a contractor.
     *
     * @tags contractors
     * @name ContractorsUpdateContractor
     * @summary Update Contractor
     * @request PATCH:/api/v1/contractors/{contractor_id}
     * @secure
     */
    contractorsUpdateContractor: (contractorId: string, data: UpdateContractorRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<UpdateContractorResponse, ErrorResponse>({
          path: `/api/v1/contractors/${contractorId}`,
          method: "PATCH",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Marks an upload as complete
     *
     * @tags files
     * @name FilesCompleteUpload
     * @summary Complete Upload
     * @request POST:/api/v1/files/{upload_id}/complete
     * @secure
     */
    filesCompleteUpload: (uploadId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Upload, ErrorResponse>({
          path: `/api/v1/files/${uploadId}/complete`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Creates an upload, returning a signed URL where the upload can be made
     *
     * @tags files
     * @name FilesCreateUpload
     * @summary Create Upload
     * @request POST:/api/v1/files/create
     * @secure
     */
    filesCreateUpload: (
      data: CreateUploadRequest,
      query?: {
        /**
         * Upload Type
         * @default "legacy"
         */
        upload_type?: "public" | "private" | "legacy";
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<Upload, any>({
          path: `/api/v1/files/create`,
          method: "POST",
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Returns the details of an upload
     *
     * @tags files
     * @name FilesGetUpload
     * @summary Get Upload
     * @request GET:/api/v1/files/{upload_id}
     * @secure
     */
    filesGetUpload: (uploadId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Upload, ErrorResponse>({
          path: `/api/v1/files/${uploadId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags files
     * @name FilesUploadImage
     * @summary Upload Image
     * @request POST:/api/v1/files/upload-image
     */
    filesUploadImage: (data: BodyUploadImageApiV1FilesUploadImagePost, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/files/upload-image`,
          method: "POST",
          body: data,
          type: ContentType.FormData,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @name HealthHealthCheck
     * @summary Health Check
     * @request GET:/api/health
     */
    healthHealthCheck: (params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/health`,
          method: "GET",
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Check if a location is supported by the app
     *
     * @tags incentives
     * @name IncentivesCheckLocation
     * @summary Check Location
     * @request POST:/api/v1/incentives/check-location
     * @deprecated
     */
    incentivesCheckLocation: (params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/incentives/check-location`,
          method: "POST",
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the documentation requirements for an incentive
     *
     * @tags incentives
     * @name IncentivesGetDocRequirements
     * @summary Get Doc Requirements
     * @request GET:/api/v1/incentives/doc-requirements/{incentive_id}
     * @secure
     */
    incentivesGetDocRequirements: (incentiveId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveDocRequirement[], any>({
          path: `/api/v1/incentives/doc-requirements/${incentiveId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags incentives
     * @name IncentivesGetFilters
     * @summary Get Filters
     * @request GET:/api/v1/incentives/filters
     * @secure
     */
    incentivesGetFilters: (params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveSearchQueryFilters, any>({
          path: `/api/v1/incentives/filters`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags incentives
     * @name IncentivesGetIncentive
     * @summary Get Incentive
     * @request GET:/api/v1/incentives/details/{incentive_id}
     * @secure
     */
    incentivesGetIncentive: (incentiveId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveDetails, any>({
          path: `/api/v1/incentives/details/${incentiveId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags incentives
     * @name IncentivesGetProvider
     * @summary Get Provider
     * @request GET:/api/v1/incentives/providers/{provider_id}
     * @secure
     */
    incentivesGetProvider: (providerId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<AppSchemasOrganizationOrganization, any>({
          path: `/api/v1/incentives/providers/${providerId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags incentives
     * @name IncentivesListIncentives
     * @summary List Incentives
     * @request POST:/api/v1/incentives/list
     * @secure
     */
    incentivesListIncentives: (data: BodyListIncentivesApiV1IncentivesListPost, params: RequestParams = {}) =>
      this.parse(
        this.request<ListIncentivesResult, any>({
          path: `/api/v1/incentives/list`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags ingestion
     * @name InternalGetActiveSnapshot
     * @summary Get Active Snapshot
     * @request POST:/api/v1/internal/get_active_snapshot
     * @secure
     */
    internalGetActiveSnapshot: (
      data: BodyGetActiveSnapshotApiV1InternalGetActiveSnapshotPost,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ServerSnapshotVersion, ErrorResponse>({
          path: `/api/v1/internal/get_active_snapshot`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags ingestion
     * @name InternalLoadSnapshot
     * @summary Load Snapshot
     * @request POST:/api/v1/internal/load_snapshot/{snapshot_version}
     * @secure
     */
    internalLoadSnapshot: (
      snapshotVersion: string,
      data: BodyLoadSnapshotApiV1InternalLoadSnapshotSnapshotVersionPost,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<any, ErrorResponse>({
          path: `/api/v1/internal/load_snapshot/${snapshotVersion}`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description OAuth2 compatible token login, get an access token for future requests
     *
     * @tags login
     * @name LoginLoginAccessToken
     * @summary Login Access Token
     * @request POST:/api/v1/login/access-token
     * @secure
     */
    loginLoginAccessToken: (data: BodyLoginAccessTokenApiV1LoginAccessTokenPost, params: RequestParams = {}) =>
      this.parse(
        this.request<Token, any>({
          path: `/api/v1/login/access-token`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.UrlEncoded,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Test access token
     *
     * @tags login
     * @name LoginTestToken
     * @summary Test Token
     * @request POST:/api/v1/login/test-token
     * @secure
     */
    loginTestToken: (params: RequestParams = {}) =>
      this.parse(
        this.request<User, any>({
          path: `/api/v1/login/test-token`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Add a note to an application
     *
     * @tags ops
     * @name OpsAddApplicationNote
     * @summary Add Application Note
     * @request POST:/api/v1/ops/application/{application_id}/notes
     * @secure
     */
    opsAddApplicationNote: (applicationId: string, data: ApplicationNoteAddRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<ApplicationNotes, any>({
          path: `/api/v1/ops/application/${applicationId}/notes`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Approve an organization claim request.
     *
     * @tags ops
     * @name OpsApproveOrgClaimRequest
     * @summary Approve Org Claim Request
     * @request POST:/api/v1/ops/users/{user_id}/approve_contractor_claim
     * @secure
     */
    opsApproveOrgClaimRequest: (userId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, any>({
          path: `/api/v1/ops/users/${userId}/approve_contractor_claim`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Delete a contractor-to-program association.
     *
     * @tags ops
     * @name OpsDeleteProgramAssociation
     * @summary Delete Program Association
     * @request DELETE:/api/v1/ops/contractor-program-associations/{association_id}
     * @secure
     */
    opsDeleteProgramAssociation: (associationId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, any>({
          path: `/api/v1/ops/contractor-program-associations/${associationId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get application notes.
     *
     * @tags ops
     * @name OpsGetApplicationNotes
     * @summary Get Application Notes
     * @request GET:/api/v1/ops/application/{application_id}/notes
     * @secure
     */
    opsGetApplicationNotes: (applicationId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ApplicationNotes, any>({
          path: `/api/v1/ops/application/${applicationId}/notes`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get application references.
     *
     * @tags ops
     * @name OpsGetApplicationReferences
     * @summary Get Application References
     * @request GET:/api/v1/ops/application/{application_id}/references
     * @secure
     */
    opsGetApplicationReferences: (applicationId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ApplicationReferences, any>({
          path: `/api/v1/ops/application/${applicationId}/references`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags ops
     * @name OpsGetQuotableJob
     * @summary Get Quotable Job
     * @request GET:/api/v1/ops/quotable-jobs/{quotable_job_id}
     * @secure
     */
    opsGetQuotableJob: (quotableJobId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<AppApiApiV1SchemasOpsQuotableJob, ErrorResponse>({
          path: `/api/v1/ops/quotable-jobs/${quotableJobId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the unverified contractor-to-certification associations. Note: This returns at most 100 unverified associations.
     *
     * @tags ops
     * @name OpsGetUnverifiedCertificationAssociations
     * @summary Get Unverified Certification Associations
     * @request GET:/api/v1/ops/contractor-certification-associations/unverified
     * @secure
     */
    opsGetUnverifiedCertificationAssociations: (params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorToCertificationAssociation[], ErrorResponse>({
          path: `/api/v1/ops/contractor-certification-associations/unverified`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the unverified contractor-to-certification associations. Note: This returns at most 100 unverified associations.
     *
     * @tags ops
     * @name OpsGetUnverifiedGoogleIds
     * @summary Get Unverified Google Ids
     * @request GET:/api/v1/ops/contractor-google-ids/unverified
     * @secure
     */
    opsGetUnverifiedGoogleIds: (params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorGoogleID[], ErrorResponse>({
          path: `/api/v1/ops/contractor-google-ids/unverified`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the unverified contractor-to-program associations. Note: This returns at most 100 unverified associations.
     *
     * @tags ops
     * @name OpsGetUnverifiedProgramAssociations
     * @summary Get Unverified Program Associations
     * @request GET:/api/v1/ops/contractor-program-associations/unverified
     * @secure
     */
    opsGetUnverifiedProgramAssociations: (params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorToProgramAssociation[], ErrorResponse>({
          path: `/api/v1/ops/contractor-program-associations/unverified`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a user by ID.
     *
     * @tags ops
     * @name OpsGetUser
     * @summary Get User
     * @request POST:/api/v1/ops/users/{user_id}
     * @secure
     */
    opsGetUser: (userId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<User, any>({
          path: `/api/v1/ops/users/${userId}`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Invalidate answers for project, also setting phases to `exception`.
     *
     * @tags ops
     * @name OpsInvalidateAnswers
     * @summary Invalidate Answers
     * @request POST:/api/v1/ops/project/answers/invalidate
     * @secure
     */
    opsInvalidateAnswers: (data: InvalidateAnswerRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, any>({
          path: `/api/v1/ops/project/answers/invalidate`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description List incentive portals.
     *
     * @tags ops
     * @name OpsListIncentivePortals
     * @summary List Incentive Portals
     * @request GET:/api/v1/ops/incentive_portals
     * @secure
     */
    opsListIncentivePortals: (params: RequestParams = {}) =>
      this.parse(
        this.request<ListIncentivePortalsResult, any>({
          path: `/api/v1/ops/incentive_portals`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description List projects.
     *
     * @tags ops
     * @name OpsListProjects
     * @summary List Projects
     * @request GET:/api/v1/ops/projects
     * @secure
     */
    opsListProjects: (
      query?: {
        /** Affiliated With */
        affiliated_with?: string | null;
        /** Portal */
        portal?: string | null;
        /** Search Str */
        search_str?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<AppApiApiV1SchemasOpsListProjectsResult, any>({
          path: `/api/v1/ops/projects`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description List users.
     *
     * @tags ops
     * @name OpsListUsers
     * @summary List Users
     * @request GET:/api/v1/ops/users
     * @secure
     */
    opsListUsers: (
      query?: {
        /** Email */
        email?: string | null;
        /** Name */
        name?: string | null;
        /**
         * Ops Users Only
         * @default true
         */
        ops_users_only?: boolean;
        /** Phone */
        phone?: string | null;
        /** Status */
        status?: UserStatusInput | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ListUsersResult, any>({
          path: `/api/v1/ops/users`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Reject an organization claim request.
     *
     * @tags ops
     * @name OpsRejectOrgClaimRequest
     * @summary Reject Org Claim Request
     * @request POST:/api/v1/ops/users/{user_id}/reject_contractor_claim
     * @secure
     */
    opsRejectOrgClaimRequest: (userId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, any>({
          path: `/api/v1/ops/users/${userId}/reject_contractor_claim`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Update application references.
     *
     * @tags ops
     * @name OpsUpdateApplicationReferences
     * @summary Update Application References
     * @request POST:/api/v1/ops/application/{application_id}/references
     * @secure
     */
    opsUpdateApplicationReferences: (
      applicationId: string,
      data: UpdateApplicationReferencesRequest,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ApplicationReferences, any>({
          path: `/api/v1/ops/application/${applicationId}/references`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Update phase status.
     *
     * @tags ops
     * @name OpsUpdatePhaseStatus
     * @summary Update Phase Status
     * @request POST:/api/v1/ops/phases/update
     * @secure
     */
    opsUpdatePhaseStatus: (data: UpdatePhaseStatusRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveApplicationPhaseEvent, any>({
          path: `/api/v1/ops/phases/update`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Verify a contractor-to-certification association.
     *
     * @tags ops
     * @name OpsVerifyCertificationAssociation
     * @summary Verify Certification Association
     * @request POST:/api/v1/ops/contractor-certification-associations/{association_id}/verify
     * @secure
     */
    opsVerifyCertificationAssociation: (associationId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorToCertificationAssociation, ErrorResponse>({
          path: `/api/v1/ops/contractor-certification-associations/${associationId}/verify`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Verify a contractor google business id.
     *
     * @tags ops
     * @name OpsVerifyGoogleId
     * @summary Verify Google Id
     * @request POST:/api/v1/ops/contractor-google-id/{contractor_id}/verify
     * @secure
     */
    opsVerifyGoogleId: (contractorId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, ErrorResponse>({
          path: `/api/v1/ops/contractor-google-id/${contractorId}/verify`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Verify a contractor-to-program association.
     *
     * @tags ops
     * @name OpsVerifyProgramAssociation
     * @summary Verify Program Association
     * @request POST:/api/v1/ops/contractor-program-associations/{association_id}/verify
     * @secure
     */
    opsVerifyProgramAssociation: (associationId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ContractorToProgramAssociation, ErrorResponse>({
          path: `/api/v1/ops/contractor-program-associations/${associationId}/verify`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Send OTP to email and save it in database
     *
     * @tags login
     * @name OtpSendEmailOtp
     * @summary Send Email Otp
     * @request POST:/api/v1/otp/email
     */
    otpSendEmailOtp: (
      query: {
        /**
         * Email
         * @format email
         */
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/otp/email`,
          method: "POST",
          query: query,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Initiate authentication using a phone number. If the phone number belongs to a user who authenticates via SSO, return the redirect URL. Otherwise send an OTP whether they have an account or not.
     *
     * @tags login
     * @name OtpSendPhoneNumberOtp
     * @summary Send Phone Number Otp
     * @request POST:/api/v1/otp/phone-number
     * @secure
     */
    otpSendPhoneNumberOtp: (
      query: {
        /** Phone Number */
        phone_number: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<InitiateAuthResponse, any>({
          path: `/api/v1/otp/phone-number`,
          method: "POST",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Verify OTP
     *
     * @tags login
     * @name OtpVerifyOtp
     * @summary Verify Otp
     * @request POST:/api/v1/otp/verify
     * @secure
     */
    otpVerifyOtp: (data: VerifyOtpBody, params: RequestParams = {}) =>
      this.parse(
        this.request<Token, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/otp/verify`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Password Recovery
     *
     * @tags login
     * @name PasswordRecoverPassword
     * @summary Recover Password
     * @request POST:/api/v1/password-recovery/{email}
     * @secure
     */
    passwordRecoverPassword: (email: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Msg, any>({
          path: `/api/v1/password-recovery/${email}`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Add a team to a project
     *
     * @tags projects
     * @name ProjectsAddTeam
     * @summary Add Team
     * @request PUT:/api/v1/projects/{project_id}/teams/{team_id}
     * @secure
     */
    projectsAddTeam: (projectId: string, teamId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Team[], ErrorResponse>({
          path: `/api/v1/projects/${projectId}/teams/${teamId}`,
          method: "PUT",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Retract all signature requests for a signature checklist item.
     *
     * @tags projects
     * @name ProjectsCancelSignatureRequests
     * @summary Cancel Signature Requests
     * @request POST:/api/v1/projects/{project_id}/checklist/{item_id}/cancel-signature-requests
     * @secure
     */
    projectsCancelSignatureRequests: (projectId: string, itemId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/cancel-signature-requests`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Complete a checklist item for a project.
     *
     * @tags projects
     * @name ProjectsCompleteChecklistItem
     * @summary Complete Checklist Item
     * @request POST:/api/v1/projects/{project_id}/checklist/{item_id}/complete
     * @secure
     */
    projectsCompleteChecklistItem: (
      projectId: string,
      itemId: string,
      data: ProjectChecklistItemSubmitRequest,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/complete`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Create a new project.
     *
     * @tags projects
     * @name ProjectsCreateProject
     * @summary Create Project
     * @request POST:/api/v1/projects/
     * @secure
     */
    projectsCreateProject: (data: ProjectCreateRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Project, ErrorResponse>({
          path: `/api/v1/projects/`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Upload a file for a checklist item.
     *
     * @tags projects
     * @name ProjectsDeleteChecklistItemFile
     * @summary Delete Checklist Item File
     * @request DELETE:/api/v1/projects/{project_id}/checklist/{item_id}/{attachment_id}
     * @secure
     */
    projectsDeleteChecklistItemFile: (
      projectId: string,
      itemId: string,
      attachmentId: string,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<any, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/${attachmentId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Delete a project.
     *
     * @tags projects
     * @name ProjectsDeleteProject
     * @summary Delete Project
     * @request DELETE:/api/v1/projects/{project_id}
     * @secure
     */
    projectsDeleteProject: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, ErrorResponse>({
          path: `/api/v1/projects/${projectId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Return a single use link to the current doc for this signature checklist item. It may be partially signed
     *
     * @tags projects
     * @name ProjectsDownloadGeneratedSignatureDocument
     * @summary Download Generated Signature Document
     * @request GET:/api/v1/projects/{project_id}/checklist/{item_id}/get-generated-document
     * @secure
     */
    projectsDownloadGeneratedSignatureDocument: (projectId: string, itemId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/get-generated-document`,
          method: "GET",
          secure: true,
          format: "blob",
          ...params,
        }),
      ),

    /**
     * @description Return a single use link to the current doc for this signature checklist item. It may be partially signed
     *
     * @tags projects
     * @name ProjectsDownloadSignedDocument
     * @summary Download Signed Document
     * @request GET:/api/v1/projects/{project_id}/checklist/{item_id}/get-signed-document
     * @secure
     */
    projectsDownloadSignedDocument: (projectId: string, itemId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/get-signed-document`,
          method: "GET",
          secure: true,
          format: "blob",
          ...params,
        }),
      ),

    /**
     * @description Export a project.
     *
     * @tags projects
     * @name ProjectsExportProject
     * @summary Export Project
     * @request POST:/api/v1/projects/{project_id}/export
     * @secure
     */
    projectsExportProject: (
      projectId: string,
      data: BodyExportProjectApiV1ProjectsProjectIdExportPost,
      query?: {
        /** Application Id */
        application_id?: string | null;
        /** Phase Name */
        phase_name?: ApplicationPhaseEnum | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<any, ErrorResponse>({
          path: `/api/v1/projects/${projectId}/export`,
          method: "POST",
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get an application.
     *
     * @tags projects
     * @name ProjectsGetApplication
     * @summary Get Application
     * @request GET:/api/v1/projects/{project_id}/applications/{application_id}
     * @secure
     */
    projectsGetApplication: (projectId: string, applicationId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveApplicationDetails, ErrorResponse>({
          path: `/api/v1/projects/${projectId}/applications/${applicationId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the checklist for a project.
     *
     * @tags projects
     * @name ProjectsGetChecklist
     * @summary Get Checklist
     * @request GET:/api/v1/projects/{project_id}/checklist
     * @secure
     */
    projectsGetChecklist: (
      projectId: string,
      query?: {
        /** Application Id */
        application_id?: string | null;
        /** Phase Name */
        phase_name?: ApplicationPhaseEnum | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ProjectChecklist, any>({
          path: `/api/v1/projects/${projectId}/checklist`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a checklist item for a project.
     *
     * @tags projects
     * @name ProjectsGetChecklistItem
     * @summary Get Checklist Item
     * @request GET:/api/v1/projects/{project_id}/checklist/{item_id}
     * @secure
     */
    projectsGetChecklistItem: (
      projectId: string,
      itemId: string,
      query?: {
        /** Application Id */
        application_id?: string | null;
        /** Phase Name */
        phase_name?: ApplicationPhaseEnum | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ProjectChecklistItemDetails, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the eligible equipment for a bundle.
     *
     * @tags projects
     * @name ProjectsGetEligibleEquipment
     * @summary Get Eligible Equipment
     * @request GET:/api/v1/projects/{project_id}/eligible-equipment
     * @secure
     */
    projectsGetEligibleEquipment: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Equipment[], any>({
          path: `/api/v1/projects/${projectId}/eligible-equipment`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags projects
     * @name ProjectsGetPhaseUpdates
     * @summary Get Phase Updates
     * @request GET:/api/v1/projects/{project_id}/applications/{application_id}/updates
     * @secure
     */
    projectsGetPhaseUpdates: (
      projectId: string,
      applicationId: string,
      query?: {
        /** Phase Name */
        phase_name?: ApplicationPhaseEnum | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<GetPhaseUpdatesResponse, ErrorResponse>({
          path: `/api/v1/projects/${projectId}/applications/${applicationId}/updates`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a project.
     *
     * @tags projects
     * @name ProjectsGetProject
     * @summary Get Project
     * @request GET:/api/v1/projects/{project_id}
     * @secure
     */
    projectsGetProject: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Project, ErrorResponse>({
          path: `/api/v1/projects/${projectId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the answers for a project.
     *
     * @tags projects
     * @name ProjectsGetProjectAnswers
     * @summary Get Project Answers
     * @request GET:/api/v1/projects/{project_id}/answers
     * @secure
     */
    projectsGetProjectAnswers: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ProjectAnswersResponse, any>({
          path: `/api/v1/projects/${projectId}/answers`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get all applications for a project
     *
     * @tags projects
     * @name ProjectsGetProjectApplications
     * @summary Get Project Applications
     * @request GET:/api/v1/projects/{project_id}/applications/
     * @secure
     */
    projectsGetProjectApplications: (
      projectId: string,
      query?: {
        /**
         * Limit
         * @default 20
         */
        limit?: number;
        /**
         * Offset
         * @default 0
         */
        offset?: number;
        /** Phase Filter */
        phase_filter?: ApplicationPhaseEnum | null;
        /** Status Filter */
        status_filter?: ApplicationPhaseStatusEnum[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<PaginatedIncentiveApplicationResponse, any>({
          path: `/api/v1/projects/${projectId}/applications/`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags projects
     * @name ProjectsGetProjectCollaborators
     * @summary Get Project Collaborators
     * @request GET:/api/v1/projects/{project_id}/collaborators
     * @secure
     */
    projectsGetProjectCollaborators: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ProjectCollaborator[], ErrorResponse>({
          path: `/api/v1/projects/${projectId}/collaborators`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags projects
     * @name ProjectsGetReadyApplications
     * @summary Get Ready Applications
     * @request GET:/api/v1/projects/{project_id}/ready-applications
     * @secure
     */
    projectsGetReadyApplications: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ProjectApplicationReadyResponse, ErrorResponse>({
          path: `/api/v1/projects/${projectId}/ready-applications`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the signature status for a signature checklist item.
     *
     * @tags projects
     * @name ProjectsGetSignatureStatus
     * @summary Get Signature Status
     * @request GET:/api/v1/projects/{project_id}/checklist/{item_id}/get-signature-status
     * @secure
     */
    projectsGetSignatureStatus: (projectId: string, itemId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<SignatureStatus[], any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/get-signature-status`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a project share token, return information about the linked project
     *
     * @tags projects
     * @name ProjectsInviteDetails
     * @summary Invite Details
     * @request GET:/api/v1/projects/invite/{token}/details
     * @secure
     */
    projectsInviteDetails: (token: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ProjectInvitation, ErrorResponse>({
          path: `/api/v1/projects/invite/${token}/details`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Generate a project share token, and text it to the provided phone number
     *
     * @tags projects
     * @name ProjectsInviteToProject
     * @summary Invite To Project
     * @request POST:/api/v1/projects/{project_id}/invite
     * @secure
     */
    projectsInviteToProject: (
      projectId: string,
      data: BodyInviteToProjectApiV1ProjectsProjectIdInvitePost,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ProjectInvitation, ErrorResponse>({
          path: `/api/v1/projects/${projectId}/invite`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags projects
     * @name ProjectsIsPhaseReadyForSubmission
     * @summary Is Phase Ready For Submission
     * @request GET:/api/v1/projects/{project_id}/applications/{application_id}/phase/{phase_name}/is_ready_for_submission
     * @secure
     */
    projectsIsPhaseReadyForSubmission: (
      projectId: string,
      applicationId: string,
      phaseName: ApplicationPhaseEnum,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<GenericResponse, SubmissionValidationErrorResponse>({
          path: `/api/v1/projects/${projectId}/applications/${applicationId}/phase/${phaseName}/is_ready_for_submission`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Check if a project is ready for export.
     *
     * @tags projects
     * @name ProjectsIsReadyForExport
     * @summary Is Ready For Export
     * @request GET:/api/v1/projects/{project_id}/is_ready_for_export
     * @secure
     */
    projectsIsReadyForExport: (
      projectId: string,
      query?: {
        /** Application Id */
        application_id?: string | null;
        /** Phase Name */
        phase_name?: ApplicationPhaseEnum | null;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<GenericResponse, SubmissionValidationErrorResponse>({
          path: `/api/v1/projects/${projectId}/is_ready_for_export`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description List projects.
     *
     * @tags projects
     * @name ProjectsListProjects
     * @summary List Projects
     * @request GET:/api/v1/projects/
     * @secure
     */
    projectsListProjects: (
      query?: {
        /**
         * Limit
         * Number of results to return
         * @default 20
         */
        limit?: number | null;
        /**
         * Offset
         * Offset of results to return
         * @default 0
         */
        offset?: number | null;
        /**
         * Query
         * Search query
         */
        query?: string | null;
        /**
         * Statuses
         * Project statuses to filter by
         */
        statuses?: ProjectStatusEnum[];
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<AppSchemasProjectListProjectsResult, any>({
          path: `/api/v1/projects/`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description List the teams for a project
     *
     * @tags projects
     * @name ProjectsListTeams
     * @summary List Teams
     * @request GET:/api/v1/projects/{project_id}/teams
     * @secure
     */
    projectsListTeams: (projectId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Team[], ErrorResponse>({
          path: `/api/v1/projects/${projectId}/teams`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Remove a team from a project
     *
     * @tags projects
     * @name ProjectsRemoveTeam
     * @summary Remove Team
     * @request DELETE:/api/v1/projects/{project_id}/teams/{team_id}
     * @secure
     */
    projectsRemoveTeam: (projectId: string, teamId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Team[], ErrorResponse>({
          path: `/api/v1/projects/${projectId}/teams/${teamId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Request a contractor.
     *
     * @tags projects
     * @name ProjectsRequestContractor
     * @summary Request Contractor
     * @request POST:/api/v1/projects/{project_id}/request-contractor
     * @secure
     */
    projectsRequestContractor: (projectId: string, data: ProjectContractorRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, ErrorResponse>({
          path: `/api/v1/projects/${projectId}/request-contractor`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Request signatures for a checklist item.
     *
     * @tags projects
     * @name ProjectsRequestSignatures
     * @summary Request Signatures
     * @request POST:/api/v1/projects/{project_id}/checklist/{item_id}/request-signatures
     * @secure
     */
    projectsRequestSignatures: (projectId: string, itemId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/request-signatures`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Resend the signature request email for a given signee role.
     *
     * @tags projects
     * @name ProjectsRerequestSignature
     * @summary Rerequest Signature
     * @request POST:/api/v1/projects/{project_id}/checklist/{item_id}/rerequest-signature/{role}
     * @secure
     */
    projectsRerequestSignature: (projectId: string, itemId: string, role: string, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/rerequest-signature/${role}`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a project share token, accept or reject the invitation
     *
     * @tags projects
     * @name ProjectsRespondToInvite
     * @summary Respond To Invite
     * @request POST:/api/v1/projects/invite/{token}/respond
     * @secure
     */
    projectsRespondToInvite: (
      token: string,
      data: BodyRespondToInviteApiV1ProjectsInviteTokenRespondPost,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<GenericResponse, ErrorResponse>({
          path: `/api/v1/projects/invite/${token}/respond`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Submit an application.
     *
     * @tags projects
     * @name ProjectsSubmitApplication
     * @summary Submit Application
     * @request POST:/api/v1/projects/{project_id}/applications/{application_id}/submit
     * @secure
     */
    projectsSubmitApplication: (
      projectId: string,
      applicationId: string,
      data: IncentiveApplicationSubmitRequest,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<IncentiveApplicationSubmitResponse, SubmissionValidationErrorResponse>({
          path: `/api/v1/projects/${projectId}/applications/${applicationId}/submit`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Update a project
     *
     * @tags projects
     * @name ProjectsUpdateProject
     * @summary Update Project
     * @request PUT:/api/v1/projects/{project_id}
     * @secure
     */
    projectsUpdateProject: (projectId: string, data: ProjectUpdateRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Project, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/projects/${projectId}`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Upload a file for a checklist item.
     *
     * @tags projects
     * @name ProjectsUploadChecklistItemFile
     * @summary Upload Checklist Item File
     * @request POST:/api/v1/projects/{project_id}/checklist/{item_id}/upload
     * @secure
     */
    projectsUploadChecklistItemFile: (
      projectId: string,
      itemId: string,
      data: BodyUploadChecklistItemFileApiV1ProjectsProjectIdChecklistItemIdUploadPost,
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ProjectChecklistItemUploadResponse, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/projects/${projectId}/checklist/${itemId}/upload`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.FormData,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags quotes
     * @name QuotesContractorsForJob
     * @summary Contractors For Job
     * @request GET:/api/v1/quotes/contractors-for-job
     * @secure
     */
    quotesContractorsForJob: (
      query: {
        /** Quotable Job Id */
        quotable_job_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<Contractor[], ErrorResponse>({
          path: `/api/v1/quotes/contractors-for-job`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Creates a new quote request object in the db, associating a QuotableJob to <= 2 contractors.
     *
     * @tags quotes
     * @name QuotesCreateQuoteRequest
     * @summary Create Quote Request
     * @request POST:/api/v1/quotes/create-quote-request
     * @secure
     */
    quotesCreateQuoteRequest: (data: QuoteRequestCreate, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, InputValidationErrorResponse | ErrorResponse>({
          path: `/api/v1/quotes/create-quote-request`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags quotes
     * @name QuotesQuotableJob
     * @summary Quotable Job
     * @request GET:/api/v1/quotes/quotable_job/{quotable_job_id}
     * @secure
     */
    quotesQuotableJob: (quotableJobId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<AppApiApiV1SchemasQuotesQuotableJob, ErrorResponse>({
          path: `/api/v1/quotes/quotable_job/${quotableJobId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsCreateIncentiveReport
     * @summary Create Incentive Report
     * @request POST:/api/v1/reports/
     * @secure
     */
    reportsCreateIncentiveReport: (data: IncentiveReportReq, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveReportRes, ErrorResponse>({
          path: `/api/v1/reports/`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsGetIncentiveReport
     * @summary Get Incentive Report
     * @request GET:/api/v1/reports/{report_id}
     * @secure
     */
    reportsGetIncentiveReport: (reportId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<IncentiveReportRes, any>({
          path: `/api/v1/reports/${reportId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsPreviewIncentiveReport
     * @summary Preview Incentive Report
     * @request GET:/api/v1/reports/preview/{report_id}
     * @secure
     */
    reportsPreviewIncentiveReport: (reportId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<string, any>({
          path: `/api/v1/reports/preview/${reportId}`,
          method: "GET",
          secure: true,
          ...params,
        }),
      ),

    /**
     * @description Reset password
     *
     * @tags login
     * @name ResetResetPassword
     * @summary Reset Password
     * @request POST:/api/v1/reset-password/
     * @secure
     */
    resetResetPassword: (data: BodyResetPasswordApiV1ResetPasswordPost, params: RequestParams = {}) =>
      this.parse(
        this.request<Msg, any>({
          path: `/api/v1/reset-password/`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a team invite token, cancel the invitation
     *
     * @tags teams
     * @name TeamsCancelInvite
     * @summary Cancel Invite
     * @request POST:/api/v1/teams/invite/{token}/cancel
     * @secure
     */
    teamsCancelInvite: (token: string, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, ErrorResponse>({
          path: `/api/v1/teams/invite/${token}/cancel`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Create a new team.
     *
     * @tags teams
     * @name TeamsCreateTeam
     * @summary Create Team
     * @request POST:/api/v1/teams/
     * @secure
     */
    teamsCreateTeam: (data: CreateTeamRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Team, ErrorResponse>({
          path: `/api/v1/teams/`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Delete a specific team by ID.
     *
     * @tags teams
     * @name TeamsDeleteTeam
     * @summary Delete Team
     * @request DELETE:/api/v1/teams/{team_id}
     * @secure
     */
    teamsDeleteTeam: (teamId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, ErrorResponse>({
          path: `/api/v1/teams/${teamId}`,
          method: "DELETE",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a specific team by ID.
     *
     * @tags teams
     * @name TeamsGetTeam
     * @summary Get Team
     * @request GET:/api/v1/teams/{team_id}
     * @secure
     */
    teamsGetTeam: (teamId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<Team, ErrorResponse>({
          path: `/api/v1/teams/${teamId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a list of projects for a specific team by ID.
     *
     * @tags teams
     * @name TeamsGetTeamProjects
     * @summary Get Team Projects
     * @request GET:/api/v1/teams/{team_id}/projects
     * @secure
     */
    teamsGetTeamProjects: (teamId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<ProjectCard[], ErrorResponse>({
          path: `/api/v1/teams/${teamId}/projects`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a team invite token, return information about the invite
     *
     * @tags teams
     * @name TeamsInviteDetails
     * @summary Invite Details
     * @request GET:/api/v1/teams/invite/{token}
     * @secure
     */
    teamsInviteDetails: (token: string, params: RequestParams = {}) =>
      this.parse(
        this.request<TeamInvite, ErrorResponse>({
          path: `/api/v1/teams/invite/${token}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Invite a user to a team.
     *
     * @tags teams
     * @name TeamsInviteUser
     * @summary Invite User
     * @request PUT:/api/v1/teams/{team_id}/invite
     * @secure
     */
    teamsInviteUser: (teamId: string, data: TeamInviteRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Team, ErrorResponse>({
          path: `/api/v1/teams/${teamId}/invite`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a list of the current user's teams.
     *
     * @tags teams
     * @name TeamsListTeams
     * @summary List Teams
     * @request GET:/api/v1/teams/
     * @secure
     */
    teamsListTeams: (params: RequestParams = {}) =>
      this.parse(
        this.request<Team[], any>({
          path: `/api/v1/teams/`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Remove a user from a team.
     *
     * @tags teams
     * @name TeamsRemoveUser
     * @summary Remove User
     * @request PUT:/api/v1/teams/{team_id}/remove
     * @secure
     */
    teamsRemoveUser: (teamId: string, data: TeamRemoveRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Team, ErrorResponse>({
          path: `/api/v1/teams/${teamId}/remove`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a team invite token, accept or reject the invitation
     *
     * @tags teams
     * @name TeamsResendInvite
     * @summary Resend Invite
     * @request POST:/api/v1/teams/invite/{token}/resend
     * @secure
     */
    teamsResendInvite: (token: string, params: RequestParams = {}) =>
      this.parse(
        this.request<TeamInvite, ErrorResponse>({
          path: `/api/v1/teams/invite/${token}/resend`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Given a team invite token, accept or reject the invitation
     *
     * @tags teams
     * @name TeamsRespondToInvite
     * @summary Respond To Invite
     * @request POST:/api/v1/teams/invite/{token}/respond
     * @secure
     */
    teamsRespondToInvite: (token: string, data: TeamInviteRespondRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<TeamInvite, ErrorResponse>({
          path: `/api/v1/teams/invite/${token}/respond`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Invite a user to a team.
     *
     * @tags teams
     * @name TeamsUpdateTeam
     * @summary Update Team
     * @request PUT:/api/v1/teams/{team_id}
     * @secure
     */
    teamsUpdateTeam: (teamId: string, data: UpdateTeamRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<Team, ErrorResponse>({
          path: `/api/v1/teams/${teamId}`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags users
     * @name UsersAcceptTos
     * @summary Accept Tos
     * @request POST:/api/v1/users/tos/accept
     * @secure
     */
    usersAcceptTos: (params: RequestParams = {}) =>
      this.parse(
        this.request<GenericResponse, any>({
          path: `/api/v1/users/tos/accept`,
          method: "POST",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Complete Signup
     *
     * @tags users
     * @name UsersCompleteSignup
     * @summary Complete Signup
     * @request POST:/api/v1/users/signup
     * @secure
     */
    usersCompleteSignup: (data: CreateUserRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<User, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/users/signup`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags users
     * @name UsersGetSupportedLocales
     * @summary Get Supported Locales
     * @request GET:/api/v1/users/supported-locales
     * @secure
     */
    usersGetSupportedLocales: (params: RequestParams = {}) =>
      this.parse(
        this.request<SupportedLocales, any>({
          path: `/api/v1/users/supported-locales`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags users
     * @name UsersGetTos
     * @summary Get Tos
     * @request GET:/api/v1/users/tos/current
     * @secure
     */
    usersGetTos: (params: RequestParams = {}) =>
      this.parse(
        this.request<string, any>({
          path: `/api/v1/users/tos/current`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get a specific user by ID.
     *
     * @tags users
     * @name UsersGetUser
     * @summary Get User
     * @request GET:/api/v1/users/{user_id}
     * @secure
     */
    usersGetUser: (userId: string, params: RequestParams = {}) =>
      this.parse(
        this.request<User, any>({
          path: `/api/v1/users/${userId}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get current user's businesses.
     *
     * @tags users
     * @name UsersGetUserOrganizations
     * @summary Get User Organizations
     * @request GET:/api/v1/users/me/businesses
     * @secure
     */
    usersGetUserOrganizations: (params: RequestParams = {}) =>
      this.parse(
        this.request<AppSchemasIncentiveOrganization[], any>({
          path: `/api/v1/users/me/businesses`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get current user
     *
     * @tags users
     * @name UsersReadUserMe
     * @summary Read User Me
     * @request GET:/api/v1/users/me
     * @secure
     */
    usersReadUserMe: (params: RequestParams = {}) =>
      this.parse(
        this.request<User, any>({
          path: `/api/v1/users/me`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Submit feedback
     *
     * @tags users
     * @name UsersSubmitFeedback
     * @summary Submit Feedback
     * @request POST:/api/v1/users/feedback
     * @secure
     */
    usersSubmitFeedback: (data: FeedbackRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/users/feedback`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Update own user.
     *
     * @tags users
     * @name UsersUpdateUserMe
     * @summary Update User Me
     * @request PUT:/api/v1/users/me
     * @secure
     */
    usersUpdateUserMe: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<User, InputValidationErrorResponse | ErrorResponse>({
          path: `/api/v1/users/me`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Update user type
     *
     * @tags users
     * @name UsersUpdateUserType
     * @summary Update User Type
     * @request POST:/api/v1/users/update-user-type
     * @secure
     */
    usersUpdateUserType: (data: UpdateUserTypeRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<User, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/users/update-user-type`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags utils
     * @name UtilsGetAhj
     * @summary Get Ahj
     * @request GET:/api/v1/utils/ahj/
     */
    utilsGetAhj: (
      query: {
        /** Address */
        address: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<Geography, any>({
          path: `/api/v1/utils/ahj/`,
          method: "GET",
          query: query,
          format: "json",
          ...params,
        }),
      ),

    /**
     * No description
     *
     * @tags utils
     * @name UtilsGetContractor
     * @summary Get Contractor
     * @request GET:/api/v1/utils/contractor
     * @secure
     */
    utilsGetContractor: (
      query: {
        /** License Number */
        license_number: string;
        /** Enumeration of supported US states. */
        state: SupportedUSStatesEnum;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<ContractorRegistryEntryResponse, ErrorResponse | InputValidationErrorResponse>({
          path: `/api/v1/utils/contractor`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Health check.
     *
     * @tags utils
     * @name UtilsHealthCheck
     * @summary Health Check
     * @request GET:/api/v1/utils/health/
     */
    utilsHealthCheck: (params: RequestParams = {}) =>
      this.parse(
        this.request<any, any>({
          path: `/api/v1/utils/health/`,
          method: "GET",
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Test emails.
     *
     * @tags utils
     * @name UtilsTestEmail
     * @summary Test Email
     * @request POST:/api/v1/utils/test-email/
     * @secure
     */
    utilsTestEmail: (
      query: {
        /**
         * Email To
         * @format email
         */
        email_to: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<Msg, any>({
          path: `/api/v1/utils/test-email/`,
          method: "POST",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Create a new wizard session and return the first set of questions.
     *
     * @tags wizard
     * @name WizardStartWizard
     * @summary Start Wizard
     * @request POST:/api/v1/wizard/start
     * @secure
     */
    wizardStartWizard: (data: WizardStartRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<WizardStartResponse, any>({
          path: `/api/v1/wizard/start`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Abort the wizard session.
     *
     * @tags wizard
     * @name WizardWizardAbort
     * @summary Wizard Abort
     * @request POST:/api/v1/wizard/abort
     * @secure
     */
    wizardWizardAbort: (data: WizardAbortRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<WizardAbortResponse, ErrorResponse>({
          path: `/api/v1/wizard/abort`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Go back to the previous question in the wizard.
     *
     * @tags wizard
     * @name WizardWizardBacktrack
     * @summary Wizard Backtrack
     * @request POST:/api/v1/wizard/backtrack
     * @secure
     */
    wizardWizardBacktrack: (data: WizardBacktrackRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<WizardBacktrackResponse, ErrorResponse>({
          path: `/api/v1/wizard/backtrack`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Complete the wizard session.
     *
     * @tags wizard
     * @name WizardWizardComplete
     * @summary Wizard Complete
     * @request POST:/api/v1/wizard/complete
     * @secure
     */
    wizardWizardComplete: (data: WizardCompleteRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<WizardCompleteResponse, ErrorResponse>({
          path: `/api/v1/wizard/complete`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the wizard session's current question.
     *
     * @tags wizard
     * @name WizardWizardCurrent
     * @summary Wizard Current
     * @request GET:/api/v1/wizard/current
     * @secure
     */
    wizardWizardCurrent: (
      query: {
        /** Session Id */
        session_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.parse(
        this.request<WizardCurrentResponse, ErrorResponse>({
          path: `/api/v1/wizard/current`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        }),
      ),

    /**
     * @description Get the next set of question for the wizard.
     *
     * @tags wizard
     * @name WizardWizardNext
     * @summary Wizard Next
     * @request POST:/api/v1/wizard/next
     * @secure
     */
    wizardWizardNext: (data: WizardNextRequest, params: RequestParams = {}) =>
      this.parse(
        this.request<WizardNextResponse, ErrorResponse>({
          path: `/api/v1/wizard/next`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),
      ),
  };
}
