import { NextPage } from "next";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import { ReactNode, useEffect } from "react";
import { hotjar } from "react-hotjar";
import "react-toastify/dist/ReactToastify.css";

import { ClientProvider } from "@/contexts/clientProvider";
import { useGoogleAnalyticsBoot } from "@/hooks/useGoogleAnalytics";
import useAuthStore from "@/store/auth";
import { isProd } from "@/utils";

import "@/styles/index.scss";

import "primereact/resources/themes/lara-light-cyan/theme.css";

type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface PromiseWithResolvers<T> {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

// Pollyfill for Promise.withResolvers
if (!Promise.withResolvers) {
  Promise.withResolvers = function <T>(): PromiseWithResolvers<T> {
    // Declare resolve and reject with "!" to assert non-null assignment
    let resolve!: (value: T | PromiseLike<T>) => void;
    let reject!: (reason?: any) => void;

    const promise = new Promise<T>((res, rej) => {
      resolve = res; // Now TypeScript understands this will be assigned here
      reject = rej; // Similarly for reject
    });

    // Return the promise, resolve, and reject
    return { promise, resolve, reject };
  };
}

const HOTJAR_VERSION = 6;
const HOTJAR_SITE_ID = 4949093;

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
});

export default function AppPage({ Component, pageProps }: AppPropsWithLayout) {
  const { userId, phoneNumber } = useAuthStore((state) => ({
    userId: state.userId,
    phoneNumber: state.phoneNumber,
  }));
  const hotjarInitialized = hotjar.initialized();
  useGoogleAnalyticsBoot();

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    if (isProd()) {
      hotjar.initialize({ id: HOTJAR_SITE_ID, sv: HOTJAR_VERSION });
    }
  }, []);

  useEffect(() => {
    if (!userId || !phoneNumber) {
      return;
    }

    if (isProd() && hotjarInitialized) {
      hotjar.identify(`user-${userId}`, {
        phone_number: phoneNumber,
      });
    }
  }, [userId, phoneNumber, hotjarInitialized]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <div>
        {/* ".p-component {" -- to override the font-family of the primereact components */}
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily} !important;
          }
          .p-component {
            font-family: ${inter.style.fontFamily} !important;
          }
        `}</style>
        <ClientProvider>
          {getLayout(<Component {...pageProps} />)}
        </ClientProvider>
      </div>
    </>
  );
}
