import {
  AUTH_QUERY_PARAMS,
  PROJECT_CHECKLIST_QUERY_PARAMS,
  WIZARD_QUERY_PARAMS,
} from "@/constants/queryParams";
import { ValueOf } from "@/types";
import { isServer } from "@/utils";

export const getQueryParamsUpdatedHref = (
  href: string,
  newQueryParams: Record<string, string | number | boolean> | string | null
) => {
  const [baseUrl, query] = href.split("?");

  if (newQueryParams === null) {
    // If newQueryParams is null, remove query params and return the base URL
    return baseUrl;
  }

  let updatedQuery = "";

  if (typeof newQueryParams === "string") {
    // If newQueryParams is a string, use it as the new query string
    updatedQuery = newQueryParams;
  } else if (typeof newQueryParams === "object") {
    // If newQueryParams is an object, construct the query string
    const queryParams = new URLSearchParams(query);

    // Update existing params or add new ones
    for (const key in newQueryParams) {
      queryParams.set(key, newQueryParams[key].toString());
    }

    updatedQuery = queryParams.toString();
  }

  return updatedQuery ? `${baseUrl}?${updatedQuery}` : baseUrl;
};

export const detachQueryParams = (url: string): string => {
  return url.split("?")[0];
};

export const getRefinedQueryParams = <
  T extends
    | ValueOf<typeof PROJECT_CHECKLIST_QUERY_PARAMS>
    | ValueOf<typeof WIZARD_QUERY_PARAMS>
    | ValueOf<typeof AUTH_QUERY_PARAMS>,
>(
  validQueryParams: T[],
  queryParams?: {
    [key in T]?: string | null;
  }
): string => {
  let currentSearch = "";
  if (!isServer()) {
    currentSearch = location.search;
  }
  const urlSearchParams = new URLSearchParams(currentSearch);

  // remove invalid query params
  for (const key of Array.from(urlSearchParams.keys())) {
    if (!validQueryParams.includes(key as unknown as T)) {
      urlSearchParams.delete(key);
    }
  }

  if (queryParams) {
    for (const key in queryParams) {
      if (validQueryParams.includes(key)) {
        if (queryParams[key] !== null) {
          urlSearchParams.set(key, queryParams[key] as string);
        } else {
          urlSearchParams.delete(key);
        }
      }
    }
  }

  return urlSearchParams.toString();
};
