import { isProd } from ".";
import * as Sentry from "@sentry/nextjs";

interface ErrorContext {
  file?: string;
  function?: string;
  additionalInfo?: Record<string, unknown>;
}

export const logError = isProd()
  ? (error: unknown, context: ErrorContext = {}) => {
      Sentry.withScope((scope) => {
        scope.setTag("where", context.file ?? "unknown");
        scope.setContext("details", {
          function: context.function ?? "unknown",
          ...context.additionalInfo,
        });
        if (error instanceof Error) {
          Sentry.captureException(error);
        } else {
          const errorMessage =
            typeof error === "string" ? error : "An unknown error occurred";
          Sentry.captureMessage(
            `Non-Error exception captured: ${errorMessage}`
          );
        }
      });
    }
  : (error: unknown, context: ErrorContext = {}) => {
      console.error("Error logged:", error, context);
    };
