import { Environments } from "@/types/environments.types";

// OK to store on client side: https://docs.launchdarkly.com/sdk/concepts/client-side-server-side#client-side-id
export const LD_CLIENT_IDS: { [key in Environments]: string } = {
  [Environments.STAGING]: "66abd995c452990fc8f56723",
  [Environments.DEVELOPMENT]: "66abd995c452990fc8f56723",
  [Environments.TEST]: "66abd995c452990fc8f56723",
  [Environments.PRODUCTION]: "66a2965a7b06c710202165a5",
  [Environments.DEMO]: "66abd9a0436e74105a0f94b0",
};

// Fallback to production flags, in case something breaks.
// We don't want to use lower environment flags on prod.
export const LD_CLIENT_ID =
  LD_CLIENT_IDS[process.env.NEXT_PUBLIC_ENV] ??
  LD_CLIENT_IDS[Environments.PRODUCTION];

export enum FEATURE_FLAGS {
  SAMPLE_FEATURE = "sample-feature",
  REACT_EXAMPLE_FLAG = "react-example-flag",
}
