import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { client } from "@/api/client";
import { QUERY_KEYS } from "@/constants";
import { SESSION_STORAGE_KEYS } from "@/constants/sessionStorageKeys";

export const useSupportedLocalesQuery = () => {
  const supportedLocaleQuery = useQuery({
    queryKey: QUERY_KEYS.utils.supportedLocales(),
    queryFn: ({ signal }) => client.api.usersGetSupportedLocales({ signal }),
  });

  // Prevent unauthenticated value from resetting user's locale after logout
  const { data } = supportedLocaleQuery;
  const locale = data?.user_locale;
  useEffect(() => {
    if (locale && localStorage.getItem(SESSION_STORAGE_KEYS.LOCALE)) {
      localStorage.setItem(SESSION_STORAGE_KEYS.LOCALE, locale);
    }
  }, [locale]);
  return supportedLocaleQuery;
};

export const useGetAhjQuery = (
  address: string,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {};

  return useQuery({
    queryKey: QUERY_KEYS.utils.ahj(address),
    queryFn: ({ signal }) => client.api.utilsGetAhj({ address }, { signal }),
    enabled,
  });
};
